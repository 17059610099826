import { ID, Response } from "../../../../_metronic/helpers";
export interface IProfileDetails {
  UserId: number | undefined;
  userId: number | undefined;
  fName: string;
  lName: string;
  email: string;
  contactPhone: string;
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
}

export type UsersQueryResponse = Response<Array<IProfileDetails>>;

export const profileDetailsInitValues: IProfileDetails = {
  fName: "",
  lName: "",
  email: "",
  contactPhone: "",
  oldPassword: "",
  newPassword: "",
  confirmPassword: "",
};
