import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { PageLink, PageTitle } from "../../../../_metronic/layout/core";
import MonacoEditor from "@monaco-editor/react";
import Select from "react-select";
import {
  ISiteDetails,
  siteDetailsInitValues as initialValues,
} from "./core/_models";
import { useAuth } from "../../../modules/auth";
import {
  AddFilter,
  UpdateFilter,
  GetFilterDetails,
  GetSite,
  GetSiteList,
  ListCollectionItems,
  ListSite,
  ListSiteCollections,
} from "./core/_requests";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ToastAlert from "../../../components/ToastAlert";
import Loader from "../../../components/loading/Loader";

interface Site {
  id: string;
  displayName: string;
}

const siteDetailsSchema = Yup.object().shape({
  site_id: Yup.string().required("Webflow Site is required"),
  name: Yup.string().required("Filter Name is required"),
  layout: Yup.string().required("Filter Layout is required"),
  collection: Yup.array()
    .of(
      Yup.object().shape({
        label: Yup.string().required(),
        value: Yup.string().required(),
      })
    )
    .min(1, "Filter Collection is required")
    .required("Filter Collection is required"),
  collection_category: Yup.array()
    .of(
      Yup.object().shape({
        label: Yup.string().required(),
        value: Yup.string().required(),
      })
    )
    .min(1, "Collection Field is required")
    .required("Collection Field is required"),
  // multiselect_switch: Yup.string().required("Filter Option is required"),
  // collection_mapping: Yup.string().required("Collection Mapping is required"),
  load_more_items: Yup.string().required("Items Per Page is required"),
});

const FilterEditPage = () => {
  const params = useParams();
  const filterId = params?.id;

  const [data, setData] = useState<any>();
  const [listCollections, setListCollections] = useState([]);
  const [listCollectionCategories, setListCollectionCategories] = useState([]);
  const [sites, setSites] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [siteUrl, setSiteUrl] = useState("");
  const [mappingOption, setMappingOption] = useState<any>({});
  const [filterOptions, setFilterOptions] = useState<any[]>([]);

  const [siteId, setSiteId] = useState(null);
  const [siteName, setSiteName] = useState(null);
  const [siteCollectionsOptions, setSiteCollectionsOptions] = useState([]);
  const [collectionCategoryOptions, setCollectionCategoryOptions] = useState(
    []
  );
  const [categoryIds, setCategoryIds] = useState<any[]>([]);

  const { currentUser, auth } = useAuth();
  const bearer = currentUser?.data.access_token;

  const location = useLocation();
  const { site_id, siteDisplayName }: any = location.state || {};
  const [selectedOption, setSelectedOption] = useState<any>(null);
  const [showEditor, setShowEditor] = useState(false);
  const [textareaData, setTextareaData] = useState("");
  const navigate = useNavigate();

  // for edit
  // list specific record based on id for edit record
  useEffect(() => {
    const FilterDetails = async () => {
      try {
        if (filterId !== undefined && filterId !== null && filterId !== "") {
          await GetFilterDetails(filterId).then((response: any) => {
            const data = {
              ...response?.data[0],
              collection_category: typeof response?.data[0]?.collection_category === 'string' ? JSON.parse(response?.data[0]?.collection_category) : response?.data[0]?.collection_category,
              collection: typeof response?.data?.[0]?.collection === 'string' ? JSON.parse(response?.data?.[0]?.collection) : response?.data?.[0]?.collection,
              all_collection_data: typeof response?.data?.[0]?.all_collection_data === 'string' ? JSON.parse(response?.data?.[0]?.all_collection_data) : response?.data?.[0]?.all_collection_data,
              collection_mapping: typeof response?.data?.[0]?.collection_mapping === 'string' ? JSON.parse(response?.data?.[0]?.collection_mapping) : response?.data?.[0]?.collection_mapping,
              multiselect_switch: typeof response?.data?.[0]?.multiselect_switch === 'string' ? JSON.parse(response?.data?.[0]?.multiselect_switch) : response?.data?.[0]?.multiselect_switch
            };
            const formatedCollection = Object?.entries(
              data?.multiselect_switch
            ).map(([key, value]) => ({ [key]: value }));

            setFilterOptions(formatedCollection);
            formik.setFieldValue("user_id", data.user_id);
            formik.setFieldValue("site_id", data.site_id);
            formik.setFieldValue("site_name", data.site_name);
            formik.setFieldValue("name", data.name);
            formik.setFieldValue("layout", data.layout);
            formik.setFieldValue("collection", data.collection);
            formik.setFieldValue(
              "collection_category",
              data.collection_category
            );
            formik.setFieldValue("load_more_items", data.load_more_items);
            formik.setFieldValue("multiselect_switch", formatedCollection);
            formik.setFieldValue(
              "all_collection_data",
              data.all_collection_data
            );
            formik.setFieldValue("collection_mapping", data.collection_mapping);
            formik.setFieldValue("site_url", data.site_url);
            formik.setFieldValue("css", showEditor ? null : data.css);
            formik.setFieldValue("date", data.date);

            setTextareaData(data.css);
            setShowEditor(data?.css !== null ? true : false);

            formik.setFieldValue(
              "multi_refine_mapping",
              data.multi_refine_mapping
            );
            setSelectedOption({ value: data.site_id, label: data.site_name });
            setSiteId(data.site_id);
            setSiteName(data.site_name);
            setSiteCollectionsOptions(data.collection);
            setCollectionCategoryOptions(data.collection_category);
            setMappingOption(data.collection_mapping);
          });
        } else {
          console.log("Data not fetched!");
        }
      } catch (error) {
        console.log(error);
      }
    };

    FilterDetails();
  }, []);

  useEffect(() => {
    setLoading(true);
    ListSite(bearer)
      .then((data) => {
        const result = data?.sites?.map((collection: any) => ({
          value: collection.id,
          label: collection.displayName,
        }));

        setSites(result);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching site data:", error);
        setLoading(false);
      });
  }, [bearer]);

  useEffect(() => {
    if (siteId !== null && bearer !== null) {
      setLoading(true);
      ListSiteCollections(siteId, bearer)
        .then((response) => {
          setListCollections(response);
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching list collection data:", error);
          setLoading(false);
        });
    }
  }, [bearer, siteId]);

  useEffect(() => {
    if (siteCollectionsOptions !== null && bearer !== null) {
      setLoading(true);
      siteCollectionsOptions?.map(async (collection: any) => {
        const collection_id = collection.value;
        ListCollectionItems(collection_id, bearer)
          .then((response) => {
            const uniqueFields: any = new Set(listCollectionCategories);
            response.items.map((e: any) => {
              const fieldData = e?.fieldData;
              if (fieldData) {
                Object.keys(fieldData).forEach((field) => {
                  uniqueFields.add(field);
                });
              }
            });
            setListCollectionCategories(Array.from(uniqueFields));
            setLoading(false);
          })
          .catch((error) => {
            console.error("Error fetching list collection items:", error);
            setLoading(false);
          });
      });
    }
  }, [bearer, siteCollectionsOptions]);

  useEffect(() => {
    setLoading(true);
    if (siteId !== null) {
      GetSiteList(siteId)
        .then(() => {
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching list collection data:", error);
          setLoading(false);
        });
    }
  }, [siteId]);

  useEffect(() => {
    if (siteId !== null && bearer !== null) {
      setLoading(true);
      GetSite(siteId, bearer)
        .then((response) => {
          setSiteUrl(response.url);
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching list collection data:", error);
          setLoading(false);
        });
    }
  }, [bearer, siteId]);

  const handleInputChange = (
    category: any,
    fieldName: any,
    selectedValue: any
  ) => {
    return setMappingOption((prevFormData: any) => ({
      ...prevFormData,
      [category]: {
        ...prevFormData[category],
        [fieldName]: selectedValue,
      },
    }));
  };

  useEffect(() => {
    if (siteDisplayName && !selectedOption) {
      setLoading(true);
      const selected: any = sites.find(
        (option: any) => option.label === siteDisplayName
      );

      setSelectedOption(selected);
      if (selected && selected.value !== null && bearer !== null) {
        const siteId = selected.value;
        ListSiteCollections(siteId, bearer)
          .then((response) => {
            setListCollections(response);
            setLoading(false);
          })
          .catch((error) => {
            console.error("Error fetching list collection data:", error);
            setLoading(false);
          });
      } else {
        setLoading(false);
      }
    }
  }, [siteDisplayName, selectedOption, bearer]);

  const formik = useFormik({
    initialValues,
    validationSchema: siteDetailsSchema,
    onSubmit: async (values) => {
      setLoading(true);
      const currentDate = new Date();
      const formattedDate = currentDate.toISOString().split("T")[0];

      const allCollections: any = [];
      await Promise.all(
        siteCollectionsOptions?.map(async (collection: any) => {
          const collection_id = collection.value;
          const response = await ListCollectionItems(collection_id, bearer);
          allCollections.push(
            ...response.items.map((e: any) => ({
              collection_id: collection.value,
              collection_name: collection.label,
              item_id: e.id,
              item_name: e.fieldData.slug,
            }))
          );
        })
      );

      const formatedFilterOptions = values?.multiselect_switch?.reduce(
        (acc, curr) => {
          const key = Object.keys(curr)[0];
          acc[key] = curr[key];
          return acc;
        },
        {}
      );

      const filterData: any = {
        user_id: auth?.data.id,
        site_id: siteId,
        site_name: siteName,
        name: values.name,
        layout: values.layout,
        collection: JSON.stringify(values.collection),
        collection_category: JSON.stringify(values.collection_category),
        collection_mapping: JSON.stringify(mappingOption),
        multiselect_switch: JSON.stringify(formatedFilterOptions),
        load_more_items: values.load_more_items,
        date: formattedDate,
        css: showEditor ? values.css : null,
        all_collection_data: JSON.stringify(allCollections.flat()),
        site_url: siteUrl,
      };

      const data = await UpdateFilter(filterData, filterId);
      if (data) {
        setLoading(false);
        ToastAlert({
          icon: "success",
          title: "Record updated successfully.",
        });
        navigate("/filters");
      } else {
        setLoading(false);
        ToastAlert({
          icon: "error",
          title: "Record not updated.",
        });
        navigate("/filters");
      }
    },
  });

  const handleCategoryChange = (selectedOptions: any) => {
    const selectedIds = selectedOptions.map((option: any) => option);
    setCategoryIds(selectedOptions);
    setCollectionCategoryOptions(selectedOptions);
    const mergedArray = selectedIds.map(({ value }: any) => {
      let obj = {};
      const entry = formik.values.multiselect_switch.find((entry) =>
        Object.keys(entry).includes(value)
      );
      if (entry) {
        obj = { [value]: entry[value] };
      } else {
        obj = {
          [value]: {
            multiselect: false,
            checkbox: false,
            radio: false,
          },
        };
      }
      return obj;
    });
    setFilterOptions(mergedArray);
    formik.setFieldValue("multiselect_switch", mergedArray);
    formik.setFieldValue("collection_category", selectedIds);
  };

  const itemsPerPageOptions = [
    { value: 1, label: "1" },
    { value: 2, label: "2" },
    { value: 3, label: "3" },
    { value: 4, label: "4" },
    { value: 5, label: "5" },
    { value: 6, label: "6" },
    { value: 7, label: "7" },
    { value: 8, label: "8" },
    { value: 9, label: "9" },
    { value: 10, label: "10" },
    { value: 11, label: "11" },
    { value: 12, label: "12" },
  ];

  const ListCollectionOptions = listCollections?.map((collection: any) => ({
    value: collection?.id || collection?.value,
    label: collection?.slug || collection?.label,
  }));

  const CategoryOptions: any = listCollectionCategories?.map(
    (collectionCategories) => ({
      value: collectionCategories,
      label: collectionCategories,
    })
  );

  const handleCancel = () => {
    navigate("/filters");
  };


  const handleCheckboxChange = (e: any, index: any, key: any) => {
    const { id, checked } = e.target;
    const [label, type] = id.split("--");
    formik.setFieldValue(`multiselect_switch.${index}`, {
      [label]: {
        multiselect: type === "multiselect" ? checked : false,
        checkbox: type === "checkbox" ? checked : false,
        radio: type === "radio" ? checked : false,
      },
    })
  };
  return (
    <div className="card mb-5 mb-xl-10">
      <div className="card-header border-0">
        <div className="card-title m-0">
          <h3 className="fw-bolder m-0">Edit Filter</h3>
        </div>
      </div>

      <div className="collapse show">
        <form onSubmit={formik.handleSubmit} noValidate className="form">
          <div className="card-body border-top p-9">
            {/* SELECT WEBFLOW SITE */}
            <div className="row mb-6">
              <label className="col-lg-4 col-form-label fw-bold fs-6">
                <span className="required">Select Webflow Site</span>
              </label>

              <div className="col-lg-8 fv-row">
                <Select
                  options={sites}
                  isSearchable={true}
                  name="site_id"
                  placeholder="Select Webflow Site"
                  value={selectedOption}
                  onChange={(selectedOption: any) => {
                    setSelectedOption(selectedOption);
                    setSiteId(selectedOption?.value);
                    setSiteName(selectedOption?.label);
                    formik.setFieldValue("site_id", selectedOption?.value);
                  }}
                />
                {formik.touched.site_id &&
                  formik.errors.site_id &&
                  formik.errors.site_id !== undefined &&
                  formik.errors.site_id !== undefined && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        <span role="alert">{formik.errors.site_id}</span>
                      </div>
                    </div>
                  )}
              </div>
            </div>

            {/* FILTER NAME */}
            <div className="row mb-6">
              <label className="col-lg-4 col-form-label required fw-bold fs-6">
                Filter Name
              </label>

              <div className="col-lg-8 fv-row">
                <input
                  type="text"
                  name="name"
                  value={formik.values.name as string}
                  onChange={formik.handleChange}
                  className="form-control form-control-lg form-control-solid"
                  placeholder="Filter Name"
                />
                {formik.touched.name &&
                  formik.errors.name &&
                  formik.errors.name !== undefined &&
                  formik.errors.name !== undefined && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        <span role="alert">{formik.errors.name}</span>
                      </div>
                    </div>
                  )}
              </div>
            </div>

            {/* FILTER LAYOUT */}
            <div className="row mb-6">
              <label className="col-lg-4 col-form-label required fw-bold fs-6">
                Filter Layout
              </label>

              <div className="col-lg-8">
                <div className="row">
                  <div className="col-lg-4 fv-row">
                    <input
                      className="form-check-input me-3"
                      name="layout"
                      type="radio"
                      value="Grid View"
                      id="gridView"
                      checked={formik.values.layout === "Grid View"}
                      onChange={formik.handleChange}
                    />
                    <label className="form-check-label" htmlFor="gridView">
                      <div className="fw-bolder text-gray-800">Grid View</div>
                    </label>
                  </div>

                  <div className="col-lg-4 fv-row">
                    <input
                      className="form-check-input me-3"
                      name="layout"
                      type="radio"
                      value="List View"
                      id="listView"
                      checked={formik.values.layout === "List View"}
                      onChange={formik.handleChange}
                    />

                    <label className="form-check-label" htmlFor="listView">
                      <div className="fw-bolder text-gray-800">List View</div>
                    </label>
                  </div>

                  <div className="col-lg-4 fv-row">
                    <input
                      className="form-check-input me-3"
                      name="layout"
                      type="radio"
                      value="List & Grid View"
                      id="bothView"
                      checked={formik.values.layout === "List & Grid View"}
                      onChange={formik.handleChange}
                    />
                    <label className="form-check-label" htmlFor="bothView">
                      <div className="fw-bolder text-gray-800">Both</div>
                    </label>
                  </div>
                </div>
                {formik.touched.layout &&
                  formik.errors.layout &&
                  formik.errors.layout !== undefined &&
                  formik.errors.layout !== undefined && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        <span role="alert">{formik.errors.layout}</span>
                      </div>
                    </div>
                  )}
              </div>
            </div>

            {/* SELECT COLLECTION */}
            <div className="row mb-6">
              <label className="col-lg-4 col-form-label fw-bold fs-6">
                <span className="required">Select Collection</span>
              </label>

              <div className="col-lg-8 fv-row">
                <Select
                  name="collection"
                  isSearchable={true}
                  placeholder="Select Collection"
                  isMulti
                  options={ListCollectionOptions}
                  value={siteCollectionsOptions}
                  onChange={(selectedOptions: any) => {
                    setSiteCollectionsOptions(selectedOptions);
                    formik.setFieldValue("collection", selectedOptions);
                  }}
                />
                {formik.touched.collection &&
                  formik.errors.collection &&
                  formik.errors.collection !== undefined &&
                  formik.errors.collection !== undefined && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        <span role="alert">{formik.errors.collection}</span>
                      </div>
                    </div>
                  )}
              </div>
            </div>

            {/* SELECT COLLECTION CATEGORIES */}
            <div className="row mb-6">
              <label className="col-lg-4 col-form-label fw-bold fs-6">
                <span className="required">Select Collection Fields</span>
              </label>
              <div className="col-lg-8 fv-row">
                <Select
                  name="collection_category"
                  isSearchable
                  placeholder="Select Collection Fields"
                  isMulti
                  options={CategoryOptions}
                  value={collectionCategoryOptions}
                  onChange={handleCategoryChange}
                />
                {formik.touched.collection_category &&
                  formik.errors.collection_category &&
                  formik.errors.collection_category !== undefined &&
                  formik.errors.collection_category !== undefined && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        <span role="alert">
                          {formik.errors.collection_category}
                        </span>
                      </div>
                    </div>
                  )}
              </div>
            </div>

            {/* FILTER OPTIONS */}
            <div className="row mb-6">
              <label className="col-lg-4 col-form-label required fw-bold fs-6">
                Filter Options
              </label>
              <div className="col-lg-8">
                <div className="row">
                  {formik?.values?.multiselect_switch?.length > 0 &&
                    Array?.isArray(formik?.values?.multiselect_switch) &&
                    formik?.values?.multiselect_switch?.map(
                      (item: any, index) => {
                        const key = Object.keys(item)[0];
                        const options = item[key];

                        return (
                          <div key={index}>
                            <h4 className="mt-5 mb-2">{key}</h4>
                            <div className="row">
                              <div className="col-lg-3 fv-row">
                                <input
                                  id={`${key}--singleSelect`}
                                  name={`multiselect_switch.${index}.${key}`}
                                  type="radio"
                                  value="Single Select"
                                  checked={
                                    (!formik?.values?.multiselect_switch[index][key].multiselect &&
                                      !formik?.values?.multiselect_switch[index][key].checkbox &&
                                      !formik?.values?.multiselect_switch[index][key].radio
                                    )
                                  }
                                  onChange={(e) => handleCheckboxChange(e, index, key)}
                                  className="form-check-input me-3"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor={`${key}--singleSelect`}
                                >
                                  <div className="fw-bolder text-gray-800">
                                    Single Select
                                  </div>
                                </label>
                              </div>

                              <div className="col-lg-3 fv-row">
                                <input
                                  id={`${key}--multiselect`}
                                  className="form-check-input me-3"
                                  name={`multiselect_switch.${index}.${key}`}
                                  type="radio"
                                  checked={
                                    formik?.values?.multiselect_switch[index][
                                      key
                                    ].multiselect
                                  }
                                  onChange={(e) => handleCheckboxChange(e, index, key)}
                                  value="Multi Select"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor={`${key}--multiselect`}
                                >
                                  <div className="fw-bolder text-gray-800">
                                    Multi Select
                                  </div>
                                </label>
                              </div>

                              <div className="col-lg-3 fv-row">
                                <input
                                  id={`${key}--checkbox`}
                                  className="form-check-input me-3"
                                  name={`multiselect_switch.${index}.${key}`}
                                  type="radio"
                                  checked={
                                    formik?.values?.multiselect_switch[index][
                                      key
                                    ].checkbox
                                  }
                                  onChange={(e) => handleCheckboxChange(e, index, key)}
                                  value="Checkbox"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor={`${key}--checkbox`}
                                >
                                  <div className="fw-bolder text-gray-800">
                                    Checkbox
                                  </div>
                                </label>
                              </div>

                              <div className="col-lg-3 fv-row">
                                <input
                                  id={`${key}--radio`}
                                  className="form-check-input me-3"
                                  name={`multiselect_switch.${index}.${key}`}
                                  type="radio"
                                  checked={
                                    formik?.values?.multiselect_switch[index][
                                      key
                                    ].radio
                                  }
                                  onChange={(e) => handleCheckboxChange(e, index, key)}
                                  value="Radio"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor={`${key}--radio`}
                                >
                                  <div className="fw-bolder text-gray-800">
                                    Radio Button
                                  </div>
                                </label>
                              </div>
                              {/* {formik.touched.multiselect_switch &&
                                formik.errors.multiselect_switch &&
                                formik.errors.multiselect_switch !==
                                  undefined &&
                                formik.errors.multiselect_switch !==
                                  undefined && (
                                  <div className="fv-plugins-message-container">
                                    <div className="fv-help-block">
                                      <span role="alert">
                                        {
                                          formik.errors
                                            .multiselect_switch as string
                                        }
                                      </span>
                                    </div>
                                  </div>
                                )} */}
                            </div>
                          </div>
                        );
                      }
                    )}
                </div>
              </div>
            </div>

            {/* ITEMS PER PAGE */}
            <div className="row mb-10">
              <label className="col-lg-4 col-form-label fw-bold fs-6">
                <span className="required">Items Per Page</span>
              </label>

              <div className="col-lg-8 fv-row">
                <Select
                  name="load_more_items"
                  options={itemsPerPageOptions}
                  placeholder="Items Per Page"
                  value={itemsPerPageOptions.find(
                    (option) => option.value === formik.values.load_more_items
                  )}
                  onChange={(selectedOption: any) => {
                    formik.setFieldValue(
                      "load_more_items",
                      selectedOption.value
                    );
                  }}
                />
                {formik.touched.load_more_items &&
                  formik.errors.load_more_items &&
                  formik.errors.load_more_items !== undefined &&
                  formik.errors.load_more_items !== undefined && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        <span role="alert">
                          {formik.errors.load_more_items}
                        </span>
                      </div>
                    </div>
                  )}
              </div>
            </div>

            {/* MAP FILTER DATA */}
            <div className="row mb-6">
              <div className="mb-10">
                <h3 className="mb-4">Map Filter Fields</h3>
              </div>
              {siteCollectionsOptions?.map((e: any, index) => {
                return (
                  <div
                    key={index}
                    className="mb-10 col-lg-3 col-xlg-3 col-md-3"
                  >
                    <div>
                      <h3 className="mb-4">{e.label.replaceAll("-", " ")}</h3>
                      <label className="mb-2">Select Image</label>
                      <Select
                        name="select_image"
                        className="w-100 w_collection_options"
                        placeholder="Select Image"
                        options={CategoryOptions}
                        value={mappingOption[e.label]?.select_image}
                        onChange={(selectedOption) =>
                          handleInputChange(
                            e.label,
                            "select_image",
                            selectedOption
                          )
                        }
                      />

                      <label className="my-2">Select Date</label>
                      <Select
                        className="w-100 w_collection_options"
                        required
                        name="select_date"
                        placeholder="Select Date"
                        options={CategoryOptions}
                        value={mappingOption[e.label]?.select_date}
                        onChange={(selectedOption) =>
                          handleInputChange(
                            e.label,
                            "select_date",
                            selectedOption
                          )
                        }
                      />
                      <label className="my-2">Select Category</label>
                      <Select
                        className="w-100 w_collection_options"
                        name="select_category"
                        placeholder="Select Category"
                        options={CategoryOptions}
                        value={mappingOption[e.label]?.select_category}
                        onChange={(selectedOption) =>
                          handleInputChange(
                            e.label,
                            "select_category",
                            selectedOption
                          )
                        }
                      />
                      <label className="my-2">Select Title</label>
                      <Select
                        className="w-100 w_collection_options"
                        required
                        name="select_title"
                        placeholder="Select Title"
                        options={CategoryOptions}
                        value={mappingOption[e.label]?.select_title}
                        onChange={(selectedOption) =>
                          handleInputChange(
                            e.label,
                            "select_title",
                            selectedOption
                          )
                        }
                      />
                      <label className="my-2">Select Discription</label>
                      <Select
                        className="w-100 w_collection_options"
                        name="select_discription"
                        placeholder="Select Discription"
                        options={CategoryOptions}
                        value={mappingOption[e.label]?.select_discription}
                        onChange={(selectedOption) =>
                          handleInputChange(
                            e.label,
                            "select_discription",
                            selectedOption
                          )
                        }
                      />
                      <label className="my-2">Select Author</label>
                      <Select
                        className="w-100 w_collection_options"
                        name="select_author"
                        placeholder="Select Author"
                        options={CategoryOptions}
                        value={mappingOption[e.label]?.select_author}
                        onChange={(selectedOption) =>
                          handleInputChange(
                            e.label,
                            "select_author",
                            selectedOption
                          )
                        }
                      />
                      <label className="my-2">Select Tag</label>
                      <Select
                        className="w-100 w_collection_options"
                        name="select_tag"
                        placeholder="Select Tag"
                        options={CategoryOptions}
                        value={mappingOption[e.label]?.select_tag}
                        onChange={(selectedOption) =>
                          handleInputChange(
                            e.label,
                            "select_tag",
                            selectedOption
                          )
                        }
                      />
                    </div>
                  </div>
                );
              })}
            </div>

            {/* CSS EDITOR */}
            <div className="mb-10">
              <div className="form-check mb-3">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="customCssCheckbox"
                  checked={showEditor}
                  onChange={(e) => setShowEditor(e.target.checked)}
                />
                <label
                  className="col-lg-4 form-label fw-bold fs-6"
                  htmlFor="customCssCheckbox"
                >
                  <span>Want to add custom CSS?</span>
                </label>
              </div>
              {showEditor && (
                <div className="col-lg-12" style={{ height: "300px" }}>
                  <MonacoEditor
                    onChange={(data: any) => {
                      formik.setFieldValue("css", data);
                    }}
                    defaultLanguage="css"
                    options={{
                      selectOnLineNumbers: true,
                      roundedSelection: false,
                      readOnly: false,
                      cursorStyle: "line",
                      automaticLayout: true,
                    }}
                    theme="vs-dark"
                    value={textareaData || ""}
                  />
                </div>
              )}
            </div>
          </div>
          {/* ACTION BUTTON */}

          <div className="card-footer d-flex justify-content-end py-6 px-9">
            <button
              type="button"
              className="btn btn-light mx-3"
              disabled={loading}
              onClick={handleCancel}
            >
              Cancel
            </button>

            <button
              type="submit"
              className="btn btn-primary"
              disabled={loading}
            >
              {/* Update */}
              {!loading && "Update"}
              {loading && (
                <span
                  className="indicator-progress"
                  style={{ display: "block" }}
                >
                  Please wait...{" "}
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </div>
      {loading && <Loader />}
    </div>
  );
};

const breadcrumbs: Array<PageLink> = [
  {
    title: "Home",
    path: "/dashboard",
    isSeparator: false,
    isActive: false,
  },
  {
    title: "",
    path: "",
    isSeparator: true,
    isActive: false,
  },
];

const FilterEditWrapper = () => {
  return (
    <>
      <PageTitle breadcrumbs={breadcrumbs}>Edit Filter</PageTitle>
      <FilterEditPage />
    </>
  );
};

export { FilterEditWrapper };
