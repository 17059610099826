import "./embedded.css";
import NotFoundImage from "../../modules/assets/images/not-found.png";

const EmbeddedItem = ({
  id,
  fieldData,
  layout,
  InOn,
  collectionMapping,
  site_url,
  all_collection_data,
}: any) => {
  const renderedItems = Object.entries(collectionMapping).map(
    ([key, value]: any) => {
      const selectImage = value.select_image?.value;
      const selectDate = value.select_date?.value;
      const selectTitle = value.select_title?.value;
      const selectCategory = value.select_category?.value;
      const selectDiscription = value.select_discription?.value;
      const selectAuthor = value.select_author?.value;
      const selectTag = value.select_tag?.value;
      return { key, selectImage, selectDate, selectTitle, selectCategory, selectDiscription, selectAuthor, selectTag };
    }
  );

  const mappedItemImage: any = renderedItems.find((item) => fieldData?.[item.selectImage]) || {};
  const mappedItemDate: any = renderedItems.find((item) => fieldData?.[item.selectDate]) || {};
  const mappedItemCategory: any = renderedItems.find((item) => fieldData?.[item.selectCategory]) || {};
  const mappedItemTitle: any = renderedItems.find((item) => fieldData?.[item.selectTitle]) || {};
  const mappedItemDiscription: any = renderedItems.find((item) => fieldData?.[item.selectDiscription]) || {};
  const mappedItemAuthor: any = renderedItems.find((item) => fieldData?.[item.selectAuthor]) || {};
  const mappedItemTag: any = renderedItems.find((item) => fieldData?.[item.selectTag]) || {};

    
  const columnClass =
    layout === "List View" || InOn === "List View"
      ? "filter-main-item d-flex full-width"
      : "filter-main-item item-width-30";

  const selectImageItem = fieldData?.[mappedItemImage?.selectImage]?.url;
  const selectDateItem = fieldData?.[mappedItemDate?.selectDate];
  const selectCategoryItem = fieldData?.[mappedItemCategory?.selectCategory];
  const selectTitleItem = fieldData?.[mappedItemTitle?.selectTitle];
  const selectDiscriptionItem = fieldData?.[mappedItemDiscription?.selectDiscription];
  const selectAuthorItem = fieldData?.[mappedItemAuthor?.selectAuthor];
  const selectTagItem = fieldData?.[mappedItemTag?.selectTag];

  const collectionItem = all_collection_data?.find(
    (e: any) => e?.item_id === id
  );

  const ensureHttpPrefix = (url: any) => {
    if (!/^https?:\/\//i.test(url)) {
      return `https://${url}`;
    }
    return url;
  };

  const correctedSiteUrl = ensureHttpPrefix(site_url);
  const urlSite = `${correctedSiteUrl}/${collectionItem?.collection_name}/${fieldData?.slug}`;

  return (
    <a
      href={collectionItem ? urlSite : "/not-found"}
      className={columnClass}
      target="_parent"
    >
      {/* IMAGE */}
      <div className="filter-thumbnail">
        <img
          className="thumbnail"
          src={selectImageItem || NotFoundImage}
          alt="Image not found"
        />
      </div>
      <div className="fitler-item-content">
        {/* DATE */}
        {Array.isArray(selectDateItem) ? (
          <>{selectDateItem.length === 0 && <div>Date not found</div>}</>
        ) : (
          <div><b>Date : </b>{selectDateItem || ""}</div>
        )}
        {/* CATEGORY */}
        {Array.isArray(selectCategoryItem) ? (
          <>{selectCategoryItem.length === 0 && <div>Category not found</div>}</>
        ) : (
          <div><b>Category : </b>
            <span
              dangerouslySetInnerHTML={{
                __html: selectCategoryItem ? selectCategoryItem : "",
              }}
              />
          </div>
        )}
        {/* TITLE */}
        <h3>
          {Array.isArray(selectTitleItem) ? (
            <>{selectTitleItem.length === 0 && <div>Title not found</div>}</>
          ) : (
            <div>{selectTitleItem || ""}</div>
          )}
        </h3>

        {/* DESCRIPTION */}
        {Array.isArray(selectDiscriptionItem) ? (
          <>{selectDiscriptionItem.length === 0 && <div>Discription not found</div>}</>
        ) : (
          <div><b>Description : </b>
            <span
              dangerouslySetInnerHTML={{
                __html: selectDiscriptionItem ? selectDiscriptionItem : "",
              }}
              />
          </div>
        )}
        {/* AUTHOR */}
        {Array.isArray(selectAuthorItem) ? (
          <>{selectAuthorItem.length === 0 && <div>Author not found</div>}</>
        ) : (
          <div><b>Author : </b>
            <span
              dangerouslySetInnerHTML={{
                __html: selectAuthorItem ? selectAuthorItem : "",
              }}
              />
          </div>
        )}
        {/* TAG */}
        {Array.isArray(selectTagItem) ? (
          <>{selectTagItem.length === 0 && <div>Tag not found</div>}</>
        ) : (
          <div><b>Tag : </b>
            <span
              dangerouslySetInnerHTML={{
                __html: selectTagItem ? selectTagItem : "",
              }}
              />
          </div>
        )}
      </div>
    </a>
  );
};

export { EmbeddedItem };
