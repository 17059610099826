import React, { useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useAuth } from "../../modules/auth";
import {
  IProfileDetails,
  profileDetailsInitValues as initialValues,
} from "./core/_models";
import { ProfileUpdate } from "./core/_requests";
import Loader from "../../components/loading/Loader";
import { useNavigate } from "react-router-dom";
import ToastAlert from "../../components/ToastAlert";

const profileDetailsSchema = Yup.object().shape({
  fName: Yup.string().required("First name is required"),
  lName: Yup.string().required("Last name is required"),
  email: Yup.string().required("Email name is required"),
  contactPhone: Yup.string().required("Contact Phone is required"),
  oldPassword: Yup.string().required("Old Password is required"),
  newPassword: Yup.string()
    .required("New Password is required")
    .test("passwords-match", "Passwords must match", function (value) {
      return value === this.parent.confirmPassword;
    }),
  confirmPassword: Yup.string()
    .required("Confirm Password is required")
    .test("passwords-match", "Passwords must match", function (value) {
      return value === this.parent.newPassword;
    }),
});

const ProfileWrapper: React.FC = () => {
  // const [data, setData] = useState<IProfileDetails>(initialValues);
  const navigate = useNavigate();

  const { currentUser } = useAuth();
  const userId = currentUser?.data.id;

  const [loading, setLoading] = useState(false);
  const formik = useFormik<IProfileDetails>({
    initialValues,
    validationSchema: profileDetailsSchema,
    onSubmit: (values: any) => {
      setLoading(true);
      values.userId = userId;
      ProfileUpdate(values)
        .then((response: any) => {
          ToastAlert({
            icon: "success",
            title: "Profile updated successfully.",
          });
          navigate("/dashboard");
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    },
  });

  const handleCancel = () => {
    navigate("/filters");
  };

  return (
    <div className="card mb-5 mb-xl-10">
      <div className="card-header border-0">
        <div className="card-title m-0">
          <h3 className="fw-bolder m-0">Profile Details</h3>
        </div>
      </div>

      <div className="collapse show">
        <form onSubmit={formik.handleSubmit} noValidate className="form">
          <div className="card-body border-top p-9">
            <div className="row mb-6">
              <label className="col-lg-4 col-form-label required fw-bold fs-6">
                Full Name
              </label>

              <div className="col-lg-8">
                <div className="row">
                  <div className="col-lg-6 fv-row">
                    <input
                      type="text"
                      className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                      placeholder="First name"
                      {...formik.getFieldProps("fName")}
                    />
                    {formik.touched.fName && formik.errors.fName && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.fName}
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="col-lg-6 fv-row">
                    <input
                      type="text"
                      className="form-control form-control-lg form-control-solid"
                      placeholder="Last name"
                      {...formik.getFieldProps("lName")}
                    />
                    {formik.touched.lName && formik.errors.lName && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.lName}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="row mb-6">
              <label className="col-lg-4 col-form-label required fw-bold fs-6">
                Email
              </label>

              <div className="col-lg-8 fv-row">
                <input
                  type="text"
                  className="form-control form-control-lg form-control-solid"
                  placeholder="Email"
                  {...formik.getFieldProps("email")}
                />
                {formik.touched.email && formik.errors.email && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.email}</div>
                  </div>
                )}
              </div>
            </div>

            <div className="row mb-6">
              <label className="col-lg-4 col-form-label fw-bold fs-6">
                <span className="required">Contact Phone</span>
              </label>

              <div className="col-lg-8 fv-row">
                <input
                  type="tel"
                  className="form-control form-control-lg form-control-solid"
                  placeholder="Phone number"
                  {...formik.getFieldProps("contactPhone")}
                />
                {formik.touched.contactPhone && formik.errors.contactPhone && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.contactPhone}
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="row mb-6">
              <label className="col-lg-4 col-form-label fw-bold fs-6">
                <span className="required">Old Password</span>
              </label>

              <div className="col-lg-8 fv-row">
                <input
                  type="text"
                  className="form-control form-control-lg form-control-solid"
                  placeholder="Old Password"
                  {...formik.getFieldProps("oldPassword")}
                />
                {formik.touched.oldPassword && formik.errors.oldPassword && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.oldPassword}
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="row mb-6">
              <label className="col-lg-4 col-form-label fw-bold fs-6">
                <span className="required">New Password</span>
              </label>

              <div className="col-lg-8 fv-row">
                <input
                  type="text"
                  className="form-control form-control-lg form-control-solid"
                  placeholder="New Password"
                  {...formik.getFieldProps("newPassword")}
                />
                {formik.touched.newPassword && formik.errors.newPassword && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.newPassword}
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="row mb-6">
              <label className="col-lg-4 col-form-label fw-bold fs-6">
                <span className="required">Confirm Password</span>
              </label>

              <div className="col-lg-8 fv-row">
                <input
                  type="text"
                  className="form-control form-control-lg form-control-solid"
                  placeholder="Confirm Password"
                  {...formik.getFieldProps("confirmPassword")}
                />
                {formik.touched.confirmPassword &&
                  formik.errors.confirmPassword && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        {formik.errors.confirmPassword}
                      </div>
                    </div>
                  )}
              </div>
            </div>
          </div>

          <div className="card-footer d-flex justify-content-end py-6 px-9">
            <button
              type="button"
              className="btn btn-light mx-3"
              disabled={loading}
              onClick={handleCancel}
            >
              Cancel
            </button>

            <button
              type="submit"
              className="btn btn-primary"
              disabled={loading}
            >
              {!loading && "Save Changes"}
              {loading && (
                <span
                  className="indicator-progress"
                  style={{ display: "block" }}
                >
                  Please wait...{" "}
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </div>
      {loading && <Loader />}
    </div>
  );
};

export { ProfileWrapper };
