import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { PageLink, PageTitle } from "../../../../_metronic/layout/core";
import Select from "react-select";
import {
  ISiteDetails,
  siteDetailsInitValues as initialValues,
} from "../../../modules/accounts/components/settings/SettingsModel";
import { useAuth } from "../../../modules/auth";
import { ListSite } from "../filter-list/core/_requests";
import {
  AddFilter,
  GetFilterDetails,
  GetSite,
  GetSiteList,
  ListCollectionItems,
  ListSiteCollections,
} from "./core/_requests";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ToastAlert from "../../../components/ToastAlert";
import Loader from "../../../components/loading/Loader";

interface Site {
  id: string;
  displayName: string;
}

const siteDetailsSchema = Yup.object().shape({
  // site_id: Yup.string().required("Webflow Site is required"),
  name: Yup.string().required("Filter Name is required"),
  layout: Yup.string().required("Filter Layout is required"),
  collection: Yup.array()
    .of(
      Yup.object().shape({
        label: Yup.string().required(),
        value: Yup.string().required(),
      })
    )
    .min(1, "Filter Collection is required")
    .required("Filter Collection is required"),
  collection_category: Yup.array()
    .of(
      Yup.object().shape({
        label: Yup.string().required(),
        value: Yup.string().required(),
      })
    )
    .min(1, "Collection Field is required")
    .required("Collection Field is required"),
  // multiselect_switch: Yup.string().required("Filter Option is required"),
  // collection_mapping: Yup.string().required("Collection Mapping is required"),
  load_more_items: Yup.string().required("Items Per Page is required"),
});

const FilterAddPage = () => {
  const [listCollections, setListCollections] = useState([]);
  const [listCollectionCategories, setListCollectionCategories] = useState([]);
  const [sites, setSites] = useState([]);
  const [loading, setLoading] = useState(false);
  const [siteUrl, setSiteUrl] = useState("");
  const [mappingOption, setMappingOption] = useState<any>({});
  const [filterOptions, setFilterOptions] = useState({});

  const [siteId, setSiteId] = useState(null);
  const [siteName, setSiteName] = useState(null);
  const [siteCollectionsIds, setListCollectionsIds] = useState([]);
  const [categoryIds, setCategoryIds] = useState([]);

  const { currentUser, auth } = useAuth();
  const bearer = currentUser?.data.access_token;

  const location = useLocation();
  const { site_id, siteDisplayName }: any = location.state || {};
  const [selectedOption, setSelectedOption] = useState<any>(null);
  const navigate = useNavigate();

  // get all sites
  useEffect(() => {
    setLoading(true);
    ListSite(bearer)
      .then((data) => {
        setSites(data.sites);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }, [bearer]);

  // get all collections of selected site
  useEffect(() => {
    if (site_id !== undefined && site_id !== null && site_id !== "") {
      setSiteId(site_id);
      setSiteName(siteDisplayName);
      setLoading(true);

      const selectedSite: any = sites.find((site: any) => site.id === site_id);
      if (selectedSite) {
        setSelectedOption({
          value: selectedSite.id,
          label: selectedSite.displayName,
        });
      }

      if (bearer) {
        ListSiteCollections(site_id, bearer)
          .then((response) => {
            setListCollections(response);
            setLoading(false);
          })
          .catch((error) => {
            console.error("Error fetching list collection data:", error);
            setLoading(false);
          });
      }
    }
  }, [site_id, sites, bearer, siteDisplayName]);

  useEffect(() => {
    if (siteCollectionsIds !== null && bearer !== null) {
      setLoading(true);
      siteCollectionsIds?.map(async (collection: any) => {        
        const collection_id = collection.value;
        ListCollectionItems(collection_id, bearer)
          .then((response) => {
            const uniqueFields: any = new Set(listCollectionCategories);
            response.items.map((e: any) => {
              const fieldData = e?.fieldData;
              if (fieldData) {
                Object.keys(fieldData).forEach((field) => {
                  uniqueFields.add(field);
                });
              }
            });
            setListCollectionCategories(Array.from(uniqueFields));
            setLoading(false);
          })
          .catch((error) => {
            console.error("Error fetching list collection items:", error);
            setLoading(false);
          });
      });
    }
  }, [bearer, siteCollectionsIds]);

  // useEffect(() => {
  //   if (siteCollectionsIds !== null && bearer !== null) {
  //     setLoading(true);
  //     Promise.all(
  //       siteCollectionsIds?.map(async (collection: any) => {
  //         const collection_id = collection.value;
  //         try {
  //           const response = await ListCollectionItems(collection_id, bearer);
  //           const uniqueFields: any = new Set();
  //           response.items.forEach((e: any) => {
  //             const fieldData = e?.fieldData;
  //             if (fieldData) {
  //               Object.keys(fieldData).forEach((field) => {
  //                 uniqueFields.add(field);
  //               });
  //             }
  //           });
  //           setListCollectionCategories(Array.from(uniqueFields));
  //         } catch (error) {
  //           console.error("Error fetching list collection items:", error);
  //         }
  //       })
  //     ).finally(() => {
  //       setLoading(false);
  //     });
  //   }
  // }, [bearer, siteCollectionsIds]);

  // useEffect(() => {
  //   if (siteId !== null) {
  //     GetSiteList(siteId)
  //       // .then((response) => setSiteUrl(response))
  //       // .then((response) => console.log(response, "front response"))
  //       .catch((error) => {
  //         console.error("Error fetching list collection data:", error);
  //       });
  //   }

  //   // setSiteUrl(data?.data?.customDomains[0]?.url);
  // });

  useEffect(() => {
    if (siteId !== null && bearer !== null) {
      setLoading(true);
      GetSite(siteId, bearer)
        .then((response) => {
          setSiteUrl(response.url);
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching site data:", error);
          setLoading(false);
        });
    }
  }, [siteId, bearer]);

  const handleInputChange = (
    category: any,
    fieldName: any,
    selectedValue: any
  ) => {
    setMappingOption((prevFormData: any) => ({
      ...prevFormData,
      [category]: {
        ...prevFormData[category],
        [fieldName]: selectedValue,
      },
    }));
  };

  const formik = useFormik({
    initialValues,
    validationSchema: siteDetailsSchema,
    onSubmit: async (values) => {
      setLoading(true);
      const currentDate = new Date();
      const formattedDate = currentDate.toISOString().split("T")[0];

      const allCollections: any = [];
      await Promise.all(
        siteCollectionsIds?.map(async (collection: any) => {
          const collection_id = collection.value;
          const response = await ListCollectionItems(collection_id, bearer);
          allCollections.push(
            ...response.items.map((e: any) => ({
              collection_id: collection.value,
              collection_name: collection.label,
              item_id: e.id,
              item_name: e.fieldData.slug,
            }))
          );
        })
      );

      const filterData: any = {
        user_id: auth?.data.id,
        site_id: siteId,
        site_name: siteName,
        name: values.name,
        layout: values.layout,
        collection: JSON.stringify(values.collection),
        collection_category: JSON.stringify(values.collection_category),
        collection_mapping: JSON.stringify(mappingOption),
        multiselect_switch: JSON.stringify(filterOptions),
        load_more_items: values.load_more_items,
        date: formattedDate,
        all_collection_data: JSON.stringify(allCollections.flat()),
        site_url: siteUrl,
      };

      const data = await AddFilter(filterData);
      if (data) {
        setLoading(false);
        ToastAlert({
          icon: "success",
          title: "Record inserted successfully.",
        });
        navigate("/filters");
      } else {
        setLoading(false);
        ToastAlert({
          icon: "error",
          title: "Record not inserted.",
        });
        navigate("/filters");
      }
    },
  });

  const handleCategoryChange = (selectedOptions: any) => {
    const selectedIds = selectedOptions.map((option: any) => {
      return option
    });
    setCategoryIds(selectedOptions);
    const newFilterOptions: any = {};
    selectedIds.forEach((e: any) => {
      newFilterOptions[e.label] = {
        multiselect: false,
        checkbox: false,
        radio: false,
      };
    });
    setFilterOptions(newFilterOptions);
    formik.setFieldValue("collection_category", selectedIds);
  };

  const handleCheckboxChange = (e: any) => {
    const { id, checked } = e.target;
    const [label, type] = id.split("--");
    setFilterOptions((prevState) => ({
      ...prevState,
      [label]: {
        multiselect: type === "multiselect" ? checked : false,
        checkbox: type === "checkbox" ? checked : false,
        radio: type === "radio" ? checked : false,
      },
    }));
  };

  const itemsPerPageOptions = [
    { value: 1, label: "1" },
    { value: 2, label: "2" },
    { value: 3, label: "3" },
    { value: 4, label: "4" },
    { value: 5, label: "5" },
    { value: 6, label: "6" },
    { value: 7, label: "7" },
    { value: 8, label: "8" },
    { value: 9, label: "9" },
    { value: 10, label: "10" },
    { value: 11, label: "11" },
    { value: 12, label: "12" },
  ];

  const ListSiteOptions = sites?.map((collection: any) => ({
    value: collection.id,
    label: collection.displayName,
  }));

  const ListCollectionOptions = listCollections?.map((collection: any) => ({
    value: collection?.id || collection?.value,
    label: collection?.slug || collection?.label,
  }));

  const CategoryOptions = listCollectionCategories?.map(
    (collectionCategories) => {
      return ({
      value: collectionCategories,
      label: collectionCategories,
    })}
  );

  const handleSiteSelection = (selectedOption: any) => {
    setSelectedOption(selectedOption);
    setSiteId(selectedOption?.value);
    setSiteName(selectedOption?.label);
    setLoading(true);
    ListSiteCollections(selectedOption?.value, bearer)
      .then((response) => {
        setListCollections(response);
        setLoading(false);
      })
      .catch((error) => {
        ToastAlert({
          icon: "error",
          title: "Error fetching list collection data.",
        });
        console.error("Error fetching list collection data:", error);
        setLoading(false);
      });
  };

  const handleCancel = () => {
    navigate("/filters");
  };

  return (
    <div className="card mb-5 mb-xl-10">
      <div className="card-header border-0">
        <div className="card-title m-0">
          <h3 className="fw-bolder m-0">Create New Filter</h3>
        </div>
      </div>

      <div className="collapse show">
        <form onSubmit={formik.handleSubmit} noValidate className="form">
          <div className="card-body border-top p-9">
            {/* SELECT WEBFLOW SITE */}
            <div className="row mb-6">
              <label className="col-lg-4 col-form-label fw-bold fs-6">
                <span className="required">Select Webflow Site</span>
              </label>

              <div className="col-lg-8 fv-row">
                <Select
                  options={ListSiteOptions}
                  isSearchable={true}
                  name="site_id"
                  placeholder="Select Webflow Site"
                  value={selectedOption}
                  onChange={handleSiteSelection}
                />
                {/* {formik.touched.site_id &&
                  formik.errors.site_id &&
                  formik.errors.site_id !== undefined &&
                  formik.errors.site_id !== undefined && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        <span role="alert">{formik.errors.site_id}</span>
                      </div>
                    </div>
                  )} */}
              </div>
            </div>

            {/* FILTER NAME */}
            <div className="row mb-6">
              <label className="col-lg-4 col-form-label required fw-bold fs-6">
                Filter Name
              </label>

              <div className="col-lg-8 fv-row">
                <input
                  type="text"
                  name="name"
                  value={formik.values.name as string}
                  onChange={formik.handleChange}
                  className="form-control form-control-lg form-control-solid"
                  placeholder="Filter Name"
                />
                {formik.touched.name &&
                  formik.errors.name &&
                  formik.errors.name !== undefined &&
                  formik.errors.name !== undefined && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        <span role="alert">{formik.errors.name}</span>
                      </div>
                    </div>
                  )}
              </div>
            </div>

            {/* FILTER LAYOUT */}
            <div className="row mb-6">
              <label className="col-lg-4 col-form-label required fw-bold fs-6">
                Filter Layout
              </label>

              <div className="col-lg-8">
                <div className="row">
                  <div className="col-lg-4 fv-row">
                    <input
                      className="form-check-input me-3"
                      name="layout"
                      type="radio"
                      value="Grid View"
                      id="gridView"
                      checked={formik.values.layout === "Grid View"}
                      onChange={formik.handleChange}
                    />
                    <label className="form-check-label" htmlFor="gridView">
                      <div className="fw-bolder text-gray-800">Grid View</div>
                    </label>
                  </div>

                  <div className="col-lg-4 fv-row">
                    <input
                      className="form-check-input me-3"
                      name="layout"
                      type="radio"
                      value="List View"
                      id="listView"
                      checked={formik.values.layout === "List View"}
                      onChange={formik.handleChange}
                    />

                    <label className="form-check-label" htmlFor="listView">
                      <div className="fw-bolder text-gray-800">List View</div>
                    </label>
                  </div>

                  <div className="col-lg-4 fv-row">
                    <input
                      className="form-check-input me-3"
                      name="layout"
                      type="radio"
                      value="List & Grid View"
                      id="bothView"
                      checked={formik.values.layout === "List & Grid View"}
                      onChange={formik.handleChange}
                    />
                    <label className="form-check-label" htmlFor="bothView">
                      <div className="fw-bolder text-gray-800">Both</div>
                    </label>
                  </div>
                </div>
                {formik.touched.layout &&
                  formik.errors.layout &&
                  formik.errors.layout !== undefined &&
                  formik.errors.layout !== undefined && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        <span role="alert">{formik.errors.layout}</span>
                      </div>
                    </div>
                  )}
              </div>
            </div>

            {/* SELECT COLLECTION */}
            <div className="row mb-6">
              <label className="col-lg-4 col-form-label fw-bold fs-6">
                <span className="required">Select Collection</span>
              </label>

              <div className="col-lg-8 fv-row">
                <Select
                  value={formik.values.collection}
                  name="collection"
                  isSearchable={true}
                  placeholder="Select Collection"
                  isMulti
                  options={ListCollectionOptions}
                  onChange={(selectedOptions: any) => {
                    setListCollectionsIds(selectedOptions);
                    formik.setFieldValue("collection", selectedOptions);
                  }}
                />
                {formik.touched.collection &&
                  formik.errors.collection &&
                  formik.errors.collection !== undefined &&
                  formik.errors.collection !== undefined && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        <span role="alert">{formik.errors.collection}</span>
                      </div>
                    </div>
                  )}
              </div>
            </div>

            {/* SELECT COLLECTION CATEGORIES */}
            <div className="row mb-6">
              <label className="col-lg-4 col-form-label fw-bold fs-6">
                <span className="required">Select Collection Fields</span>
              </label>
              <div className="col-lg-8 fv-row">
                <Select
                  name="collection_category"
                  isSearchable
                  placeholder="Select Collection Fields"
                  isMulti
                  options={CategoryOptions}
                  onChange={handleCategoryChange}
                />
                {formik.touched.collection_category &&
                  formik.errors.collection_category &&
                  formik.errors.collection_category !== undefined &&
                  formik.errors.collection_category !== undefined && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        <span role="alert">
                          {formik.errors.collection_category}
                        </span>
                      </div>
                    </div>
                  )}
              </div>
            </div>

            {/* FILTER OPTIONS */}
            <div className="row mb-6">
              <label className="col-lg-4 col-form-label required fw-bold fs-6">
                Filter Options
              </label>
              <div className="col-lg-8">
                <div className="row">
                  {categoryIds.length > 0 &&
                    Array.isArray(categoryIds) &&
                    categoryIds.map((e: any, index) => (
                      <div key={index}>
                        <h4 className="mt-5 mb-2">
                          {e.label.replaceAll("-", " ")}
                        </h4>
                        <div className="row">
                          <div className="col-lg-3 fv-row">
                            <input
                              id={`${e.label}--singleSelect`}
                              name={`${e.label}-filterOption`}
                              type="radio"
                              value="Single Select"
                              defaultChecked
                              onChange={handleCheckboxChange}
                              className="form-check-input me-3"
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`${e.label}--singleSelect`}
                            >
                              <div className="fw-bolder text-gray-800">
                                Single Select
                              </div>
                            </label>
                          </div>

                          <div className="col-lg-3 fv-row">
                            <input
                              id={`${e.label}--multiselect`}
                              className="form-check-input me-3"
                              name={`${e.label}-filterOption`}
                              type="radio"
                              // checked={
                              //   formik.values[`${e}-filterOption`] === "Multi Select"
                              // }
                              onChange={handleCheckboxChange}
                              value="Multi Select"
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`${e.label}--multiselect`}
                            >
                              <div className="fw-bolder text-gray-800">
                                Multi Select
                              </div>
                            </label>
                          </div>

                          <div className="col-lg-3 fv-row">
                            <input
                              id={`${e.label}--checkbox`}
                              className="form-check-input me-3"
                              name={`${e.label}-filterOption`}
                              type="radio"
                              // checked={
                              //   formik.values[`${e}-filterOption`] === "Checkbox"
                              // }
                              onChange={handleCheckboxChange}
                              value="Checkbox"
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`${e.label}--checkbox`}
                            >
                              <div className="fw-bolder text-gray-800">
                                Checkbox
                              </div>
                            </label>
                          </div>

                          <div className="col-lg-3 fv-row">
                            <input
                              id={`${e.label}--radio`}
                              className="form-check-input me-3"
                              name={`${e.label}-filterOption`}
                              type="radio"
                              // checked={
                              //   formik.values[`${e}-filterOption`] === "Radio"
                              // }
                              onChange={handleCheckboxChange}
                              value="Radio"
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`${e.label}--radio`}
                            >
                              <div className="fw-bolder text-gray-800">
                                Radio Button
                              </div>
                            </label>
                          </div>
                          {/* {formik.touched.multiselect_switch &&
                            formik.errors.multiselect_switch &&
                            formik.errors.multiselect_switch !== undefined &&
                            formik.errors.multiselect_switch !== undefined && (
                              <div className="fv-plugins-message-container">
                                <div className="fv-help-block">
                                  <span role="alert">
                                    {formik.errors.multiselect_switch as string}
                                  </span>
                                </div>
                              </div>
                            )} */}
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>

            {/* ITEMS PER PAGE */}
            <div className="row mb-10">
              <label className="col-lg-4 col-form-label fw-bold fs-6">
                <span className="required">Items Per Page</span>
              </label>

              <div className="col-lg-8 fv-row">
                <Select
                  name="load_more_items"
                  options={itemsPerPageOptions}
                  placeholder="Items Per Page"
                  value={itemsPerPageOptions.find(
                    (option) => option.value === formik.values.load_more_items
                  )}
                  onChange={(selectedOption: any) => {
                    formik.setFieldValue(
                      "load_more_items",
                      selectedOption.value
                    );
                  }}
                />
                {formik.touched.load_more_items &&
                  formik.errors.load_more_items &&
                  formik.errors.load_more_items !== undefined &&
                  formik.errors.load_more_items !== undefined && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        <span role="alert">
                          {formik.errors.load_more_items}
                        </span>
                      </div>
                    </div>
                  )}
              </div>
            </div>

            {/* MAP FILTER DATA */}
            <div className="row mb-6">
              <div className="mb-10">
                <h3 className="mb-4">Map Filter Fields</h3>
              </div>
              {siteCollectionsIds?.map((lab: any, index) => (
                <div key={index} className="mb-10 col-lg-3 col-xlg-3 col-md-3">
                  <div>
                    <h3 className="mb-4">{lab?.label?.replaceAll("-", " ")}</h3>
                    <label className="mb-2">Select Image</label>
                    <Select
                      name="select_image"
                      className="w-100 w_collection_options"
                      placeholder="Select Image"
                      options={CategoryOptions}
                      value={mappingOption?.select_image}
                      onChange={(e) =>
                        handleInputChange(lab.label, "select_image", e)
                      }
                    />
                    <label className="my-2">Select Date</label>
                    <Select
                      className="w-100 w_collection_options"
                      required
                      name="select_date"
                      placeholder="Select Date"
                      options={CategoryOptions}
                      value={mappingOption?.select_date}
                      onChange={(e) =>
                        handleInputChange(lab.label, "select_date", e)
                      }
                    />
                    <label className="my-2">Select Category</label>
                    <Select
                      className="w-100 w_collection_options"
                      name="select_category"
                      placeholder="Select Category"
                      options={CategoryOptions}
                      value={mappingOption?.select_category}
                      onChange={(e) =>
                        handleInputChange(lab.label, "select_category", e)
                      }
                    />
                    <label className="my-2">Select Title</label>
                    <Select
                      className="w-100 w_collection_options"
                      required
                      name="select_title"
                      placeholder="Select Title"
                      options={CategoryOptions}
                      value={mappingOption?.select_title}
                      onChange={(e) =>
                        handleInputChange(lab.label, "select_title", e)
                      }
                    />
                    <label className="my-2">Select Discription</label>
                    <Select
                      className="w-100 w_collection_options"
                      name="select_discription"
                      placeholder="Select Discription"
                      options={CategoryOptions}
                      value={mappingOption?.select_discription}
                      onChange={(e) =>
                        handleInputChange(lab.label, "select_discription", e)
                      }
                    />
                    <label className="my-2">Select Author</label>
                    <Select
                      className="w-100 w_collection_options"
                      name="select_author"
                      placeholder="Select Author"
                      options={CategoryOptions}
                      value={mappingOption?.select_author}
                      onChange={(e) =>
                        handleInputChange(lab.label, "select_author", e)
                      }
                    />
                    <label className="my-2">Select Tag</label>
                    <Select
                      className="w-100 w_collection_options"
                      name="select_tag"
                      placeholder="Select Tag"
                      options={CategoryOptions}
                      value={mappingOption?.select_tag}
                      onChange={(e) =>
                        handleInputChange(lab.label, "select_tag", e)
                      }
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
          {/* ACTION BUTTON */}

          <div className="card-footer d-flex justify-content-end py-6 px-9">
            <button
              type="button"
              className="btn btn-light mx-3"
              disabled={loading}
              onClick={handleCancel}
            >
              Cancel
            </button>

            <button
              type="submit"
              className="btn btn-primary"
              disabled={loading}
            >
              {/* Submit */}
              {!loading && "Submit"}
              {loading && (
                <span
                  className="indicator-progress"
                  style={{ display: "block" }}
                >
                  Please wait...{" "}
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </div>
      {loading && <Loader />}
    </div>
  );
};

const breadcrumbs: Array<PageLink> = [
  {
    title: "Home",
    path: "/dashboard",
    isSeparator: false,
    isActive: false,
  },
  {
    title: "",
    path: "",
    isSeparator: true,
    isActive: false,
  },
];

const FilterAddWrapper = () => {
  return (
    <>
      <PageTitle breadcrumbs={breadcrumbs}>Create New Filter</PageTitle>
      <FilterAddPage />
    </>
  );
};

export { FilterAddWrapper };
