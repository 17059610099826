import { useEffect, useState } from "react";
import * as Yup from "yup";
import clsx from "clsx";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { getUserByToken, login } from "../core/_requests";
import { toAbsoluteUrl } from "../../../../_metronic/helpers";
import { useAuth } from "../core/Auth";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import ToastAlert from "../../../components/ToastAlert";

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email("Wrong email format")
    .min(3, "Minimum 3 characters")
    .max(50, "Maximum 50 characters")
    .required("Email is required"),
  password: Yup.string()
    .min(3, "Minimum 3 characters")
    .max(50, "Maximum 50 characters")
    .required("Password is required"),
});

const initialValues = {
  email: "abc@zealousweb.com",
  password: "enneeilJGGih",
};

export function Login() {
  const [loading, setLoading] = useState(false);
  const { saveAuth, setCurrentUser } = useAuth();
  const navigate = useNavigate();
  // const [token, setToken] = useState(null);

  // useEffect(() => {
  //   const params = new URLSearchParams(window.location.search);
  //   const authorizationCode = params.get("code");

  //   console.log(window.location.search, " - window.location.search");
  //   console.log(params, "params");

  //   if (authorizationCode) {
  //     exchangeCodeForToken(authorizationCode);
  //   } else if (authorizationCode?.length && authorizationCode?.length < 64) {
  //     toast.error("Webflow authorized code not found, try again.");
  //   }
  // }, []);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       setLoading(true);

  //       const apiUrl = `${process.env.REACT_APP_API_URL}/webflowAuthorizedUser`;
  //       const tokenApi = token;

  //       const response = await axios.post(apiUrl, { tokenApi });

  //       const authorizedData = response.data;

  //       if (authorizedData) {
  //         const authInsert = authorizedData?.data?.insertId

  //         const { email, firstName, lastName } = authorizedData?.webflowAuthorizedUser;

  //         toast.success('Webflow user authorized, login here.');

  //         const registerData = await axios.post(`${process.env.REACT_APP_API_URL}/register`, {
  //           id: authInsert,
  //           email,
  //           firstName,
  //           lastName
  //         });

  //         if (registerData) {
  //           navigate('/');
  //         }
  //       }
  //     } catch (error) {
  //       // console.error('Error making API request:', error.message);
  //       toast.error('Webflow user not authorized, login again.');
  //     } finally {
  //       setLoading(false);
  //     }
  //   };

  //   if (token) {
  //     fetchData();
  //   }
  // }, [token, navigate]);

  // const exchangeCodeForToken = async (authorizationCode: string) => {
  //   const apiUrl = `${process.env.REACT_APP_API_URL}/callback`;
  //   const encodedRedirectUri = `${process.env.REACT_APP_API_URL}/login`;
  //   const { REACT_APP_CLIENT_ID, REACT_APP_CLIENT_SECRET } = process.env;
  //   const grantType = "authorization_code";

  //   try {
  //     setLoading(true);

  //     const response = await axios.post(apiUrl, {
  //       client_id: REACT_APP_CLIENT_ID,
  //       client_secret: REACT_APP_CLIENT_SECRET,
  //       redirect_URI: encodedRedirectUri,
  //       code: authorizationCode,
  //       grant_type: grantType,
  //     });

  //     const accessToken = response.data.access_token;
  //     localStorage.setItem("accessToken", accessToken);
  //     // setToken(accessToken);
  //   } catch (error) {
  //     console.error("Error exchanging code for access token:");
  //     toast.error("Access token not authorized, try again.");
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      try {
        const { data: auth } = await login(values.email, values.password);
        if (auth.status === 200) {
          saveAuth(auth);
          const { data: user } = await getUserByToken(
            auth.api_token,
            auth?.data?.id
          );
          if (user.status === 200) {
            setCurrentUser(user);
            ToastAlert({
              icon: "success",
              title: "Login successfully.",
            });
            navigate("/dashboard");
          } else {
            navigate("/login");
          }
        }
      } catch (error) {
        console.error(error);
        saveAuth(undefined);
        setStatus("The login details are incorrect");
        setSubmitting(false);
        setLoading(false);
      }
    },
  });

  return (
    <form
      className="form w-100"
      onSubmit={formik.handleSubmit}
      noValidate
      id="kt_login_signin_form"
    >
      <div className="text-center mb-11">
        <h1 className="text-gray-900 fw-bolder mb-3">Sign In</h1>
      </div>

      {formik.status ? (
        <div className="mb-lg-15 alert alert-danger">
          <div className="alert-text font-weight-bold">{formik.status}</div>
        </div>
      ) : null}

      <div className="fv-row mb-8">
        <label className="form-label fs-6 fw-bolder text-gray-900">Email</label>
        <input
          placeholder="Email"
          {...formik.getFieldProps("email")}
          className={clsx(
            "form-control bg-transparent",
            { "is-invalid": formik.touched.email && formik.errors.email },
            {
              "is-valid": formik.touched.email && !formik.errors.email,
            }
          )}
          type="email"
          name="email"
          autoComplete="off"
        />
        {formik.touched.email && formik.errors.email && (
          <div className="fv-plugins-message-container">
            <span role="alert">{formik.errors.email}</span>
          </div>
        )}
      </div>
      <div className="fv-row mb-3">
        <label className="form-label fw-bolder text-gray-900 fs-6 mb-0">
          Password
        </label>
        <input
          type="password"
          autoComplete="off"
          placeholder="Password"
          {...formik.getFieldProps("password")}
          className={clsx(
            "form-control bg-transparent",
            {
              "is-invalid": formik.touched.password && formik.errors.password,
            },
            {
              "is-valid": formik.touched.password && !formik.errors.password,
            }
          )}
        />
        {formik.touched.password && formik.errors.password && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">
              <span role="alert">{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>
      <div className="d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8">
        <div />
      </div>
      <div className="d-grid mb-10">
        <button
          type="submit"
          id="kt_sign_in_submit"
          className="btn btn-primary"
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className="indicator-label">Continue</span>}
          {loading && (
            <span className="indicator-progress" style={{ display: "block" }}>
              Please wait...
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          )}
        </button>
      </div>
    </form>
  );
}
