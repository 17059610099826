import axios from "axios";
import { UsersQueryResponse } from "./_models";

const API_URL = import.meta.env.VITE_APP_API_URL;
const UPDATE_PROFILE = `${API_URL}/profile/update`;

const ProfileUpdate = async (data: any) => {
  return axios
    .post(UPDATE_PROFILE, data )
    .then((response: any) => response.data)
};

export { ProfileUpdate };