import clsx from "clsx";
import { toAbsoluteUrl } from "../../../helpers";
import { Link } from "react-router-dom";
import slugify from "slugify";
import { useEffect, useState } from "react";
import { ListSite } from "../../../../app/pages/filters/filter-list/core/_requests";
import { useAuth } from "../../../../app/modules/auth";
import Loader from "../../../../app/components/loading/Loader";

type Props = {
  className?: string;
  bgColor?: string;
};
interface Site {
  id: string;
  displayName: string;
}

const TilesWidget2 = ({ className, bgColor = "#663259" }: Props) => {
  const [sites, setSites] = useState<Site[]>([]);
  const [loading, setLoading] = useState(false);

  const { currentUser } = useAuth();

  const bearer = currentUser?.data.access_token;

  useEffect(() => {
    const siteList = async () => {
      setLoading(true);
      try {
        ListSite(bearer).then((data) => {
          setSites(data.sites);
          setLoading(false);
        });
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };
    siteList();
  }, [bearer]);

  return (
    <div className="container-fluid">
      <div className="row">
        {Array.isArray(sites) &&
          sites.map((site) => (
            <div
              key={site.id}
              className={clsx(
                "card h-175px bgi-no-repeat bgi-size-contain",
                className
              )}
              style={{
                backgroundColor: bgColor,
                backgroundPosition: "right",
                backgroundImage: `url("${toAbsoluteUrl(
                  "media/svg/misc/taieri.svg"
                )}")`,
              }}
            >
              <div className="card-body d-flex flex-column justify-content-between">
                <h2 className="text-white fw-bold mb-5">{site?.displayName}</h2>

                <div className="m-0">
                  <Link
                    to={`/filters`}
                    state={{
                      site_id: site.id,
                      siteDisplayName: site.displayName,
                    }}
                    className="btn btn-danger fw-semibold px-6 py-3"
                  >
                    Select Site
                  </Link>
                </div>
              </div>
            </div>
          ))}
      </div>
      {loading && <Loader />}
    </div>
  );
};

export { TilesWidget2 };
