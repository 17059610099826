import { lazy, FC, Suspense } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { MasterLayout } from "../../_metronic/layout/MasterLayout";
import TopBarProgress from "react-topbar-progress-indicator";
import { DashboardWrapper } from "../pages/dashboard/DashboardWrapper";
import { getCSSVariableValue } from "../../_metronic/assets/ts/_utils";
import { WithChildren } from "../../_metronic/helpers";
import { FilterEditWrapper } from "../pages/filters/filter-edit/FilterEditWrapper";
import { EmbeddedWrapper } from "../pages/embedded/EmbeddedWrapper";
import { FilterAddWrapper } from "../pages/filters/filter-add/FilterAddWrapper";
import { ProfileWrapper } from "../pages/profile/ProfileWrapper";
const FiltersPage = lazy(() => import("../pages/filters/FiltersPage"));
const PrivateRoutes = () => {
  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path="auth/*" element={<Navigate to="/dashboard" />} />
        {/* Pages */}
        <Route path="dashboard" element={<DashboardWrapper />} />
        <Route path="filters/:id/:name" element={<FilterAddWrapper />} />
        <Route path="filters/create" element={<FilterAddWrapper />} />
        <Route path="filters/edit/:id" element={<FilterEditWrapper />} />
        <Route path="profile" element={<ProfileWrapper />} />
        <Route
          path="filters"
          element={
            <SuspensedView>
              <FiltersPage />
            </SuspensedView>
          }
        />

        {/* Page Not Found */}
        <Route path="*" element={<Navigate to="/error/404" />} />
      </Route>
    </Routes>
  );
};

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue("--bs-primary");
  TopBarProgress.config({
    barColors: {
      "0": baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  });
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
};

export { PrivateRoutes };
