import { ID, Response } from "../../../../../_metronic/helpers";

export type User = {
  id?: ID;
  name?: string;
  site_name?: string;
  layout?: string;
  date?: string;
  collection?: {
    length?: number;
  }[];
  // avatar?: string
  // email?: string
  // position?: string
  // role?: string
  // last_login?: string
  // two_steps?: boolean
  // joined_day?: string
  // online?: boolean
  // initials?: {
  //   label: string
  //   state: string
  // }
};

export interface ISiteDetails {
  user_id: string | unknown;
  site_id: string | unknown;
  site_name: string | unknown;
  name: string | unknown;
  layout: string | unknown;
  collection: any[] | string;
  collection_category: string | unknown;
  collection_mapping: string | unknown;
  load_more_items: number | unknown;
  multi_refine_mapping: string | unknown;
  multiselect_switch: any[];
  all_collection_data: string | unknown;
  date: string | unknown;
  site_url: string | unknown;
  css?: any;
}

export const siteDetailsInitValues: ISiteDetails = {
  user_id: "",
  site_id: "",
  site_name: "",
  name: "",
  layout: "",
  collection: [],
  collection_category: "",
  collection_mapping: "",
  load_more_items: 10,
  multi_refine_mapping: "",
  multiselect_switch: [],
  all_collection_data: "",
  date: "",
  site_url: "",
  css: null,
};

export type UsersQueryResponse = Response<Array<User>>;

export const initialUser: User = {
  // avatar: 'avatars/300-6.jpg',
  // position: 'Art Director',
  // role: 'Administrator',
  name: "",
  // email: '',
};
