import axios from "axios";
import { User, UsersQueryResponse } from "./_models";

const API_URL = import.meta.env.VITE_APP_API_URL;
const LIST_SITE = `${API_URL}/ListSite`;
const LIST_COLLECTIONS = `${API_URL}/ListCollections`;
const LIST_COLLECTION_ITEM = `${API_URL}/ListCollectionItems`;
const GET_SITE_LIST = `${API_URL}/filter/getSiteList`;
const GET_SITE = `${API_URL}/GetSite`;
const ADD_FILTER = `${API_URL}/filter/add`;
const UPDATE_FILTER = `${API_URL}/filter/filterUpdate`;
const FILTER_DETAILS = `${API_URL}/filter/listDetails`;
const FILTER_DATA = `${API_URL}/filter/getFilterCss`;

type FilterData = {
  user_id: string | null;
  site_id: string | null;
  site_name: string | null;
  name: string | null;
  layout: string | null;
  collection: any | null;
  collection_category: any | null;
  collection_mapping: any | null;
  multi_refine_mapping: any | null;
  multiselect_switch: any | null;
  load_more_items: any | null;
  date: string | null;
  all_collection_data: any[] | null;
  site_url: string | null;
};

const ListSite = async (Bearer: string | undefined) => {
  return axios.post(LIST_SITE, { Bearer }).then((response) => response.data);
};

const ListSiteCollections = (
  site_id: string | null,
  Bearer: string | undefined
) => {
  return axios
    .post(LIST_COLLECTIONS, {
      site_id,
      Bearer,
    })
    .then((response) => response.data.collections);
};

const GetSiteList = async (site_id: string | null) => {
  const response = await axios.post(GET_SITE_LIST, {
    site_id,
  });
  // return console.log(response.data, "api response");
};

const AddFilter = async (filterData: FilterData) => {
  const response = await axios.post(ADD_FILTER, filterData);
  return response;
};

const UpdateFilter = async (filterData: FilterData, filterId: any) => {
  const response = await axios.post(`${UPDATE_FILTER}/${filterId}`, filterData);
  return response;
};

const GetFilterDetails = async (id: any) => {
  const response = await axios.get(`${FILTER_DETAILS}/${id}`);
  return response.data;
};

const GetFilterData = async (id: any) => {
  const response = await axios.get(`${FILTER_DATA}/${id}`);
  return response.data;
};

const GetSite = async (site_id: string | null, Bearer: string | undefined) => {
  const response = await axios.post(GET_SITE, {
    site_id,
    Bearer,
  });
  return response.data?.customDomains[0];
};

const ListCollectionItems = async (
  collection_id: string,
  Bearer: string | undefined
) => {
  // const responses = await Promise.all(
  return axios
    .post(LIST_COLLECTION_ITEM, {
      collection_id,
      Bearer,
    })
    .then((response) => response.data);
  // );

  // return responses;
};

// const ListCollectionItems = async (
//   collection_ids: string[],
//   Bearer: string | undefined
// ) => {
//   const responses = await Promise.all(
//     collection_ids.map((collection_id) => {
//       return axios
//         .post(LIST_COLLECTION_ITEM, {
//           collection_id,
//           Bearer,
//         })
//         .then((response) => response.data);
//     })
//   );

//   return responses;
// };

export {
  ListSite,
  ListSiteCollections,
  GetSiteList,
  AddFilter,
  UpdateFilter,
  GetFilterDetails,
  GetFilterData,
  GetSite,
  ListCollectionItems,
};
