export interface IProfileDetails {
  fName: string;
  lName: string;
  email: string;
  contactPhone: string;
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
}

export interface ISiteDetails {
  user_id: string | unknown;
  site_id: string | unknown;
  site_name: string | unknown;
  name: string | unknown;
  layout: string | unknown;
  collection: string | unknown;
  collection_category: string | unknown;
  collection_mapping: string | unknown;
  load_more_items: number | unknown;
  multi_refine_mapping: string | unknown;
  multiselect_switch: string | unknown;
  all_collection_data: string | unknown;
  date: string | unknown;
  site_url: string | unknown;
}

export interface IUpdateEmail {
  newEmail: string;
  confirmPassword: string;
}

export interface IUpdatePassword {
  currentPassword: string;
  newPassword: string;
  passwordConfirmation: string;
}

export interface IConnectedAccounts {
  google: boolean;
  github: boolean;
  stack: boolean;
}

export interface IEmailPreferences {
  successfulPayments: boolean;
  payouts: boolean;
  freeCollections: boolean;
  customerPaymentDispute: boolean;
  refundAlert: boolean;
  invoicePayments: boolean;
  webhookAPIEndpoints: boolean;
}

export interface INotifications {
  notifications: {
    email: boolean;
    phone: boolean;
  };
  billingUpdates: {
    email: boolean;
    phone: boolean;
  };
  newTeamMembers: {
    email: boolean;
    phone: boolean;
  };
  completeProjects: {
    email: boolean;
    phone: boolean;
  };
  newsletters: {
    email: boolean;
    phone: boolean;
  };
}

export interface IDeactivateAccount {
  confirm: boolean;
}

export const profileDetailsInitValues: IProfileDetails = {
  fName: "Max",
  lName: "Smith",
  email: "demo@example.com",
  contactPhone: "044 3276 454 935",
  oldPassword: "",
  newPassword: "",
  confirmPassword: "",
};

export const siteDetailsInitValues: ISiteDetails = {
  user_id: "",
  site_id: "",
  site_name: "",
  name: "",
  layout: "",
  collection: [],
  collection_category: "",
  collection_mapping: "",
  load_more_items: 10,
  multi_refine_mapping: "",
  multiselect_switch: "",
  all_collection_data: "",
  date: "",
  site_url: "",
};

export const updateEmail: IUpdateEmail = {
  newEmail: "support@keenthemes.com",
  confirmPassword: "",
};

export const updatePassword: IUpdatePassword = {
  currentPassword: "",
  newPassword: "",
  passwordConfirmation: "",
};

export const connectedAccounts: IConnectedAccounts = {
  google: true,
  github: true,
  stack: false,
};

export const emailPreferences: IEmailPreferences = {
  successfulPayments: false,
  payouts: true,
  freeCollections: false,
  customerPaymentDispute: true,
  refundAlert: false,
  invoicePayments: true,
  webhookAPIEndpoints: false,
};

export const notifications: INotifications = {
  notifications: {
    email: true,
    phone: true,
  },
  billingUpdates: {
    email: true,
    phone: true,
  },
  newTeamMembers: {
    email: true,
    phone: false,
  },
  completeProjects: {
    email: false,
    phone: true,
  },
  newsletters: {
    email: false,
    phone: false,
  },
};

export const deactivateAccount: IDeactivateAccount = {
  confirm: false,
};
