function Loader() {
  const styles: React.CSSProperties = {
    borderRadius: "0.475rem",
    boxShadow: "0 0 50px 0 rgb(82 63 105 / 15%)",
    backgroundColor: "#fff",
    color: "#7e8299",
    fontWeight: "500",
    margin: "0",
    width: "auto",
    padding: "1rem 2rem",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    position: "fixed",
    textAlign: "center",
  };

  return (
    <>
      <div style={styles}>
        <img
          src={"/media/logos/primary-logo-dark.png"}
          alt="Loading ..."
          width={80}
        />
      </div>
    </>
  );
}

export default Loader;
