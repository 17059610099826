import clsx from "clsx";
import { useState } from "react";
import { KTIcon } from "../../../helpers";
import { CreateAppModal } from "../../../partials";
import { useLayout } from "../../core";
import { DefaultTitle } from "../header/page-title/DefaultTitle";
import { Link, useLocation } from "react-router-dom";

const Toolbar1 = () => {
  const { classes } = useLayout();
  const [showCreateAppModal, setShowCreateAppModal] = useState<boolean>(false);
  const location = useLocation();

  const isDashboardRoute = location.pathname === "/dashboard";

  return (
    <>
      <div className="toolbar py-5 py-lg-15" id="kt_toolbar">
        <div
          id="kt_toolbar_container"
          className={clsx(
            classes.toolbarContainer.join(" "),
            "d-flex flex-stack"
          )}
        >
          <DefaultTitle />
          {/* {!isDashboardRoute && ( */}
            {/* <div className="d-flex align-items-center py-1">
              <Link
                to="/sitedetails"
                className="btn bg-body btn-active-color-primary"
              >
                Create New Filter
              </Link>
            </div> */}
          {/* )} */}
        </div>
      </div>
      <CreateAppModal
        show={showCreateAppModal}
        handleClose={() => setShowCreateAppModal(false)}
      />
    </>
  );
};

export { Toolbar1 };
