import axios from "axios";
import { User, UsersQueryResponse } from "./_models";

const API_URL = import.meta.env.VITE_APP_API_URL;
const EMBEDDED_CODE = `${API_URL}/filter/embedded_code`;
const LIST_COLLECTION_ITEM = `${API_URL}/ListCollectionItems`;
const LOAD_MORE_ITEMS = `${API_URL}/FetchItemsApi`;

const EmbeddedCode = async (user_id: any, id: any) => {
  const response = await axios.get(
    `${EMBEDDED_CODE}/user_id=${user_id}&id=${id}`
  );
  return response.data;
};

const ListCollectionItems = async (
  collection_id: string,
  Bearer: string | undefined
) => {
  return axios
    .post(LIST_COLLECTION_ITEM, {
      collection_id,
      Bearer,
    })
    .then((response) => response);
};

const LoadMoreItems = async (collection_id: any, Bearer: any, offsetId: any, Limit: any) => {
  const response = await axios.post(LOAD_MORE_ITEMS, {
    collection_id: collection_id,
    Bearer: Bearer,
    offsetId: offsetId,
    Limit: Limit,
  });
  return response;
};

export { EmbeddedCode, ListCollectionItems, LoadMoreItems };
