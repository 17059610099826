function generateOptionsForCategory(categoryValue: any, sportList: any, data: any) {
    return sportList
        .filter((e: any, index: any, self: any) => (
            index === self.findIndex((t: any) => (
                `${t.fieldData?.[categoryValue]}` === `${e.fieldData?.[categoryValue]}`
            ))
        ))
        .filter((e: any) => e.fieldData?.[categoryValue] !== undefined && e.fieldData?.[categoryValue] !== null)
        .map((e: any) => {
            const fieldValue = e.fieldData?.[categoryValue];
            if (Array.isArray(fieldValue)) {
                return fieldValue.map(el => {
                    const matchingMapping = data?.multi_refine_mapping?.itemsFields?.find((m: any) => m.value === el);
                    return {
                        label: matchingMapping ? matchingMapping.label : `${el}`,
                        value: `${el}`
                    };
                });
            } else {
                const matchingMapping = data?.multi_refine_mapping?.itemsFields?.find((m: any) => m.value === fieldValue);
                return [{
                    label: matchingMapping ? matchingMapping.label : `${fieldValue}`,
                    value: `${fieldValue}`
                }];
            }
        })
        .flat()
        .reduce((acc: any, current: any) => {
            const x = acc.find((item: any) => item.value === current.value);
            if (!x) {
                return acc.concat([current]);
            } else {
                return acc;
            }
        }, []);
}

export { generateOptionsForCategory }