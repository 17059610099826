import { Bounce, toast } from "react-toastify";
import { injectStyle } from "react-toastify/dist/inject-style";

if (typeof window !== "undefined") {
  injectStyle();
}

const ToastAlert: any = ({ icon, title }: any) => {

  const options: any = {
    // timer: timer,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    theme: "colored",
    TransitionEvent: Bounce,
  };

  if (icon === "success") {
    toast.success(title, options);
  } else if (icon === "error") {
    toast.error(title, options);
  }
};

export default ToastAlert;
