import { useIntl } from "react-intl";
import { PageTitle } from "../../../_metronic/layout/core";
import { useLocation } from "react-router-dom";
import CryptoJS from "crypto-js";
import { useEffect, useMemo, useRef, useState } from "react";
import {
  EmbeddedCode,
  ListCollectionItems,
  LoadMoreItems,
} from "./core/_requests";
import { generateOptionsForCategory } from "./components/FilterOption";
import { useAuth } from "../../modules/auth";
import closeimg from "../../modules/assets/images/close.png";
import { EmbeddedItem } from "./embedded-item";
import Select from "react-select";
import Loader from "../../components/loading/Loader";

const sortAscendingImg = "../../../../media/logos/sort-1.png";
const sortDescendingImg = "../../../../media/logos/sort-2.png";
const EmbeddedPage = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const encrypt_user_id: any = queryParams.get("user_id");
  const encrypt_id: any = queryParams.get("id");
  const linkBytes_user_id = CryptoJS.AES.decrypt(
    encrypt_user_id,
    import.meta.env.VITE_APP_CRYPTOJS
  );
  const decryptedLink_user_id = linkBytes_user_id?.toString(CryptoJS.enc.Utf8);
  const linkBytes_id = CryptoJS.AES.decrypt(
    encrypt_id,
    import.meta.env.VITE_APP_CRYPTOJS
  );

  const decryptedLink_id = linkBytes_id?.toString(CryptoJS.enc.Utf8);

  const user_id = decryptedLink_user_id;
  const id = decryptedLink_id;
  //   const [data, setData] = useState({});
  const [data, setData] = useState<any>({ collection: [] });
  // const [accessToken, setAccessToken] = useState([]);
  const [isOn, setIsOn] = useState("");
  const [allCollectionData, setAllCollectionData] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [sportList, setSportList] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState<any>({});
  const [singleSelectedCategories, setSingleSelectedCategories] = useState<any>(
    {}
  );
  const [sortDesAsc, setSortDesAsc] = useState(true);
  const [isLoader, setIsLoader] = useState(false);
  const [offsets, setOffsets] = useState<any>({});
  const loaditems = data?.load_more_items;
  const loadMoreIncrement = loaditems;
  const [visibleItemCount, setVisibleItemCount] = useState<any>("");

  const { currentUser }: any = useAuth();
  const contentRef: any = useRef(null);
  useEffect(() => {
    const bodyElement = document.querySelector("body");
    // if (bodyElement) {
    //   bodyElement.style.overflow = "hidden";
    // }
    function heightChangedCallback() {
      if (contentRef?.current) {
        const height = contentRef?.current?.scrollHeight;
        window.parent.postMessage(
          { type: "iframeResize", id: encrypt_id, height: height },
          "*"
        );
      }
    }
    if (contentRef && contentRef?.current !== null) {
      window.onload = heightChangedCallback;
      const watchedElement: any = contentRef.current;

      const observer = new MutationObserver(function () {
        if (watchedElement?.scrollHeight !== watchedElement.dataset.oldHeight) {
          watchedElement.dataset.oldHeight = watchedElement?.scrollHeight;
          heightChangedCallback();
        }
      });
      observer.observe(watchedElement, { childList: true, subtree: true });
      watchedElement.dataset.oldHeight = watchedElement.clientHeight;

      window.addEventListener("resize", heightChangedCallback);
    }
  }, [contentRef.current]);

  useEffect(() => {
    if (data?.load_more_items) {
      setVisibleItemCount(data.load_more_items);
    }
  }, [data]);

  useEffect(() => {
    setSportList(allCollectionData);
  }, [allCollectionData]);

  function getFilteredList() {
    let filteredList = sportList;
    if (searchQuery) {
      const lowerCaseSearchTerm = searchQuery.toLowerCase();
      const dataXcategory = data?.collection_category;
      filteredList = filteredList.filter((item: any) =>
        dataXcategory.some(
          (xcate: any) =>
            (item.fieldData?.[xcate.value] !== undefined &&
              String(item.fieldData?.[xcate.value])
                .toLowerCase()
                .includes(lowerCaseSearchTerm)) ||
            (item.fieldData?.name !== undefined &&
              String(item.fieldData?.name)
                .toLowerCase()
                .includes(lowerCaseSearchTerm))
        )
      );
    }
    Object.entries(selectedCategories).forEach(
      ([categoryName, categoryValue]) => {
        if (categoryValue) {
          filteredList = dynamicFilter(
            filteredList,
            categoryName,
            categoryValue
          );
        }
      }
    );
    Object.entries(singleSelectedCategories).forEach(
      ([categoryName, categoryValue]) => {
        if (categoryValue) {
          filteredList = dynamicFilter(
            filteredList,
            categoryName,
            categoryValue
          );
        }
      }
    );
    return filteredList;
  }

  function dynamicFilter(list: any, propertyName: any, propertyValue: any) {
    return list.filter((item: any) => {
      const itemPropertyValues = item.fieldData?.[propertyName];
      if (Array.isArray(itemPropertyValues) && Array.isArray(propertyValue)) {
        return itemPropertyValues.some((id) => propertyValue.includes(id));
      } else {
        if (Array.isArray(propertyValue) && propertyValue.length > 0) {
          const firstElement = propertyValue[0];
          if (typeof firstElement === "string") {
            return propertyValue.includes(itemPropertyValues?.toString());
          } else if (
            typeof firstElement === "object" &&
            "value" in firstElement
          ) {
            return propertyValue.some(
              (obj: any) => obj.value === itemPropertyValues?.toString()
            );
          }
        }
      }
      return itemPropertyValues && propertyValue.includes(itemPropertyValues.toString());
    });
  }

  const filteredList = useMemo(getFilteredList, [
    searchQuery,
    selectedCategories,
    singleSelectedCategories,
    sportList,
    data?.collection_category,
  ]);

  function handleSingleSelect(selectedOption: any, categoryName: any) {
    const selectedValue = selectedOption ? selectedOption.value : null;
    setSingleSelectedCategories((prev: any) => ({
      ...prev,
      [categoryName]: selectedValue,
    }));
  }

  function handleCategoryChange(event: any, categoryName: any) {
    // MULTI SELECT
    if (data?.multiselect_switch?.[categoryName]?.multiselect == true) {
      const selectedOptions = Array.isArray(event) ? event : [event];
      const selectedValues = selectedOptions.map((option: any) => ({
        value: option.value,
        label: option.label,
      }));
      if (selectedValues.length === 0) {
        setSelectedCategories({});
      } else {
        setSelectedCategories((prev: any) => ({
          ...prev,
          [categoryName]: selectedValues,
        }));
      }
    }
    // RADIO    
    else if (data?.multiselect_switch?.[categoryName]?.radio === true) {
      const eventValue = event.target.value;
      setSelectedCategories({
        ...selectedCategories,
        [categoryName]: eventValue,
      });
    }
    // CHECKBOX
    else if (data?.multiselect_switch?.[categoryName]?.checkbox === true) {
      const { value, checked } = event.target;
      setSelectedCategories((prev: any) => {
        const updatedCategories = {
          ...prev,
          [categoryName]: checked
            ? [...(prev[categoryName] || []), value]
            : prev[categoryName]?.filter((v: any) => v !== value),
        };

        if (!checked && updatedCategories[categoryName]?.length === 0) {
          delete updatedCategories[categoryName];
        }

        return updatedCategories;
      });
    } else {
      const { value, checked } = event.target;
      setSelectedCategories((prev: any) => ({
        ...prev,
        [categoryName]: checked
          ? [...(prev[categoryName] || []), value]
          : prev[categoryName].filter((v: any) => v !== value),
      }));
    }
    setVisibleItemCount(loaditems);
  }

  const handleToggle = (e: any) => {
    setIsOn(e);
  };

  function handleSearchChange(event: any) {
    setSearchQuery(event.target.value);
    setVisibleItemCount(loaditems);
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await EmbeddedCode(user_id, id);
        if (response?.success && response?.data?.length > 0) {
          setData({
            ...response?.data[0],
            collection_category: typeof response?.data[0]?.collection_category === 'string' ? JSON.parse(response?.data[0]?.collection_category) : response?.data[0]?.collection_category,
            collection: typeof response?.data?.[0]?.collection === 'string' ? JSON.parse(response?.data?.[0]?.collection) : response?.data?.[0]?.collection,
            all_collection_data: typeof response?.data?.[0]?.all_collection_data === 'string' ? JSON.parse(response?.data?.[0]?.all_collection_data) : response?.data?.[0]?.all_collection_data,
            collection_mapping: typeof response?.data?.[0]?.collection_mapping === 'string' ? JSON.parse(response?.data?.[0]?.collection_mapping) : response?.data?.[0]?.collection_mapping,
            multiselect_switch: typeof response?.data?.[0]?.multiselect_switch === 'string' ? JSON.parse(response?.data?.[0]?.multiselect_switch) : response?.data?.[0]?.multiselect_switch
          });
        }
      } catch (error) {
        console.log(error);
        // toast.error(error?.response?.data?.message);
      }
    };

    if (id && user_id) {
      fetchData();
    }
  }, [id, user_id]);

  useEffect(() => {
    const fetchDataForCollections = async () => {
      try {
        setIsLoading(true);
        let collectionData;
        if (Array.isArray(data.collection)) {
          collectionData = await Promise.all(
            data.collection?.map(async (collection: any) => {
              const collection_id = collection.value;
              const Bearer = currentUser?.data.access_token;
              const response = await ListCollectionItems(collection_id, Bearer);
              setIsLoading(false);
              return response?.data?.items;
            })
          );
        } else {
          const response = await ListCollectionItems(
            data.collection.value,
            currentUser?.data.access_token
          );
          setIsLoading(false);
          collectionData = [response?.data?.items];
        }
        const flattenedCollectionData = collectionData.reduce(
          (acc, val) => acc.concat(val),
          []
        );
        setAllCollectionData(flattenedCollectionData);
      } catch (error) {
        setIsLoading(false);
        console.error("Error fetching data for collections", error);
      }
    };

    if (data.collection) {
      fetchDataForCollections();
    }
  }, [data, currentUser?.data]);

  useEffect(() => {
    const initialOffsets = data.collection.reduce(
      (acc: any, collection: any) => {
        acc[collection.value] = 0;
        return acc;
      },
      {}
    );
    setOffsets(initialOffsets);
  }, [data]);

  const displayedItems = filteredList.slice(0, visibleItemCount);

  const handleLoadMore = async () => {
    setIsLoader(true);
    await new Promise((resolve) => setTimeout(resolve, 500));
    LoadMoreItem();
    setVisibleItemCount((prevCount: any) => prevCount + loadMoreIncrement);
    setIsLoader(false);
  };

  const LoadMoreItem = async () => {
    try {
      const fetchPromises = data.collection?.map(async (collection: any) => {
        const currentOffset = offsets[collection.value] || 0;
        const response = await LoadMoreItems(
          collection.value,
          currentUser?.data.access_token,
          currentOffset.toString(),
          100
        );

        if (response.data.items.length > 0) {
          setOffsets((prevOffsets: any) => ({
            ...prevOffsets,
            [collection.value]: currentOffset + response.data.items.length,
          }));
          return response.data.items;
        } else {
          return [];
        }
      });

      const results = await Promise.all(fetchPromises);
      const newItems = results.flat();
      if (newItems.length > 0) {
        setAllCollectionData((prevData: any) => [...prevData, ...newItems]);
      }
    } catch (error) {
      console.error("Error fetching more data for collections", error);
    } finally {
      setIsLoading(false);
    }
  };

  const selectRefs = useRef({});

  const resetFilterBtn = async (e: any) => {
    e.preventDefault();
    Object.values(selectRefs.current).forEach((selectRef: any) => {
      if (
        selectRef &&
        selectRef &&
        typeof selectRef.clearValue === "function"
      ) {
        selectRef.clearValue();
      }
    });
    setSearchQuery("");
    setSelectedCategories({});
    setSingleSelectedCategories({});
    setVisibleItemCount(loaditems);
  };

  const handleSingleSelectRemove = (categoryName: any) => {
    setSingleSelectedCategories((prevCategories: any) => {
      const { [categoryName]: _, ...remainingCategories } = prevCategories;
      return remainingCategories;
    });

    setSelectedCategories((prevCategories: any) => {
      const updatedCategories = { ...prevCategories };
      delete updatedCategories[categoryName];
      return updatedCategories;
    });

    setVisibleItemCount(loaditems);
  };

  const handleRemoveCategory = (categoryName: any, valueToRemove: any) => {
    if (
      data?.multiselect_switch[categoryName]?.multiselect === true ||
      data?.multiselect_switch[categoryName].checkbox === true
    ) {
      setSelectedCategories((prevCategories: any) => {
        const filteredValues = prevCategories[categoryName]?.filter(
          (value: any) => value !== valueToRemove
        );
        if (filteredValues.length === 0) {
          const { [categoryName]: _, ...remainingCategories } = prevCategories;
          return remainingCategories;
        } else {
          return {
            ...prevCategories,
            [categoryName]: filteredValues,
          };
        }
      });
    } else {
      const updatedCategories: any = { ...selectedCategories };
      delete updatedCategories[categoryName];
      setSelectedCategories(updatedCategories);
    }
    setVisibleItemCount(loaditems);
  };

  const sortList = (list: any) => {
    return [...list].sort((a, b) =>
      a.fieldData.slug.localeCompare(b.fieldData.slug)
    );
  };

  const sortAscending = () => {
    setSortDesAsc((prev) => !prev);
    const sortedList: any = sortList(sportList);
    if (!sortDesAsc) {
      sortedList.reverse();
    }
    setSportList(sortedList);
  };

  const filters = (category: any) => {
    return (
      <div className={`multi-options ${(data?.multiselect_switch[category.value]?.checkbox || data?.multiselect_switch[category.value]?.radio) ? 'w-100 d-flex align-items-center flex-column' : ''} ${data?.multiselect_switch[category.value]?.radio ? 'flex-wrap' : ''}`} key={category.value}>
        {/* CHECKBOX */}
        {data?.multiselect_switch[category.value]?.checkbox ? (
          <>
            <label className="w-100 font-weight-bold text-capitalize">{category?.label?.replaceAll(
              "-",
              " "
            )}
            </label>
            <div className="dropdown">
              {generateOptionsForCategory(
                category.value,
                sportList,
                data
              )
                .sort((a: any, b: any) =>
                  a.label.localeCompare(b.label)
                )
                .map((option: any, index: any) => (
                  <label
                    className="d-flex align-top gap-2"
                    key={index}
                  >
                    <input
                      type="checkbox"
                      className={`${(data?.multiselect_switch[category.value]?.checkbox || data?.multiselect_switch[category.value]?.radio)
                        ? "" : ''}`}
                      onChange={(selectedOption) =>
                        handleCategoryChange(
                          selectedOption,
                          category?.value
                        )
                      }
                      checked={selectedCategories?.[
                        category?.value
                      ]?.includes(option?.value)}
                      value={option.value}
                    />
                    <span>{option.label.replaceAll("-", " ")}</span>
                  </label>
                ))}
            </div>
          </>
        ) : // RADIO BUTTON
          data?.multiselect_switch[category.value]?.radio ? (
            <>
              <label className="w-100 font-weight-bold text-capitalize">{category?.label?.replaceAll(
                "-",
                " "
              )}</label>
               <div className="dropdown w-100">
              {generateOptionsForCategory(
                category.value,
                sportList,
                data
              )
                .sort((a: any, b: any) =>
                  a.label.localeCompare(b.label)
                )
                .map((option: any, index: any) => (
                  <label
                    className="d-flex align-center gap-4 m-3 mt-0"
                    key={index}
                  >
                    <input
                      type="radio"
                      className=""
                      onChange={(selectedOption) => {
                        handleCategoryChange(
                          selectedOption,
                          category?.value
                        )
                      }
                      }
                      checked={selectedCategories[
                        category.value
                      ]?.includes(option.value)}
                      value={option.value}
                    />
                    {option.label.replaceAll("-", " ")}
                  </label>
                ))}
                </div>
            </>
          ) : data?.multiselect_switch[category.value]?.multiselect ? (
            // MULTI SELECT
            <div
              className="cat-box"
              key={selectedCategories[category.value]}
            >
              <div className="dropdown" style={{ width: "300px" }}>
                <Select
                  placeholder={`Multi Select (${category?.label?.replace(
                    "-",
                    " "
                  )})`}
                  value={selectedCategories[category.label] || []}
                  isMulti
                  backspaceRemovesValue={false}
                  options={generateOptionsForCategory(
                    category.value,
                    sportList,
                    data
                  )
                    .map((option: any) => ({
                      value: option.value,
                      label: option.label.replaceAll("-", " "),
                    }))
                    .sort((a: any, b: any) =>
                      a.label.localeCompare(b.label)
                    )}
                  onChange={(selectedOption: any) =>
                    handleCategoryChange(
                      selectedOption,
                      category.value
                    )
                  }
                />
              </div>
            </div>
          ) : (
            <Select
              key={category.value}
              name="singleSelect"
              placeholder={`Single Select (${category.label.replace(
                "-",
                " "
              )})`}
              value={
                singleSelectedCategories[category.value]
                  ? {
                    value: singleSelectedCategories[category.value],
                    label: generateOptionsForCategory(
                      category.value,
                      sportList,
                      data
                    ).find(
                      (option: any) =>
                        option.value ===
                        singleSelectedCategories[category.value]
                    ).label,
                  }
                  : null
              }
              options={generateOptionsForCategory(
                category.value,
                sportList,
                data
              )
                .map((option: any) => ({
                  value: option.value,
                  label: option.label.replaceAll("-", " "),
                }))
                .sort((a: any, b: any) =>
                  a.label.localeCompare(b.label)
                )}
              onChange={(selectedOption) =>
                handleSingleSelect(selectedOption, category.value)
              }
              isClearable
            />
          )}
      </div>
    )
  }


  return (
    <>
      <style>{data?.css}</style>
      <div className="main-wrapper-embedded" id="content" ref={contentRef}>
        <div className="container">
          <div className="filter-with-sidebar d-flex gap-40 justify-content-space-between flex-wrap">
            <div className="Sidebar">
              <div className="filter-options d-flex align-center gap flex-wrap">
                <div className="cat-box">
                  <input
                    type="search"
                    onChange={handleSearchChange}
                    placeholder="Search"
                  />
                </div>
                {data?.collection_category === null ? (
                  ""
                ) : (
                  <>
                    {data?.collection_category?.map((category: any) => {
                      if (data?.multiselect_switch[category.value]?.checkbox || data?.multiselect_switch[category.value]?.radio)
                        return filters(category)
                    })}
                  </>
                )}
                <div>
                  <div className="fs-4 cursor-pointer" onClick={resetFilterBtn}>Reset All</div>
                </div>
              </div>
            </div>
            <div className="filter-content">
              <div className="filter-options d-flex align-center gap flex-wrap">
                {data?.collection_category === null ? (
                  ""
                ) : (
                  <div className="d-flex justify-content-between w-100 align-items-start">
                    <div className="d-flex gap-4 flex-wrap">
                      {data?.collection_category?.map((category: any) => {
                        if (!data?.multiselect_switch[category.value]?.checkbox && !data?.multiselect_switch[category.value]?.radio)
                          return filters(category)
                      })}
                    </div>
                  </div>
                )}
                <div className="filter-sorting-options d-flex align-center gap-24 justify-content-right w-100">
                  <div className="sort-by">Sort by</div>
                  <div className="sort-by asc-desc">
                    <img
                      style={{ width: "20px" }}
                      className="filter"
                      onClick={sortAscending}
                      src={sortDesAsc ? sortAscendingImg : sortDescendingImg}
                      alt=""
                    />
                  </div>
                  {data?.layout === "List & Grid View" && (
                    <>
                      <div className="sort-by grid">
                        <div className="d-flex align-center">
                          <div
                            onClick={() => handleToggle("Grid View")}
                            className={`grid-land ${isOn === "Grid View" ? "active" : ""
                              }`}
                          >
                            <svg
                              width="18"
                              height="18"
                              viewBox="0 0 18 18"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M2 2H6V6H2V2ZM0 2C0 0.895431 0.895431 0 2 0H6C7.10457 0 8 0.895431 8 2V6C8 7.10457 7.10457 8 6 8H2C0.895431 8 0 7.10457 0 6V2ZM2 12H6V16H2V12ZM0 12C0 10.8954 0.895431 10 2 10H6C7.10457 10 8 10.8954 8 12V16C8 17.1046 7.10457 18 6 18H2C0.895431 18 0 17.1046 0 16V12ZM15.9999 2H11.9999V6H15.9999V2ZM11.9999 0C10.8954 0 9.99993 0.895431 9.99993 2V6C9.99993 7.10457 10.8954 8 11.9999 8H15.9999C17.1045 8 17.9999 7.10457 17.9999 6V2C17.9999 0.895431 17.1045 0 15.9999 0H11.9999ZM12 12H16V16H12V12ZM10 12C10 10.8954 10.8954 10 12 10H16C17.1046 10 18 10.8954 18 12V16C18 17.1046 17.1046 18 16 18H12C10.8954 18 10 17.1046 10 16V12Z"
                                fill="white"
                              />
                            </svg>
                          </div>
                          <div
                            onClick={() => handleToggle("List View")}
                            className={`grid-box ${isOn === "List View" ? "active" : ""
                              }`}
                          >
                            <svg
                              width="18"
                              height="18"
                              viewBox="0 0 18 18"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M2 2H6V6H2V2ZM0 2C0 0.895431 0.895431 0 2 0H6C7.10457 0 8 0.895431 8 2V6C8 7.10457 7.10457 8 6 8H2C0.895431 8 0 7.10457 0 6V2ZM2 12H6V16H2V12ZM0 12C0 10.8954 0.895431 10 2 10H6C7.10457 10 8 10.8954 8 12V16C8 17.1046 7.10457 18 6 18H2C0.895431 18 0 17.1046 0 16V12ZM10.0002 2C10.0002 1.44772 10.448 1 11.0002 1H17.0002C17.5525 1 18.0002 1.44772 18.0002 2C18.0002 2.55229 17.5525 3 17.0002 3H11.0002C10.448 3 10.0002 2.55229 10.0002 2ZM11.0002 11C10.448 11 10.0002 11.4477 10.0002 12C10.0002 12.5523 10.448 13 11.0002 13H17.0002C17.5525 13 18.0002 12.5523 18.0002 12C18.0002 11.4477 17.5525 11 17.0002 11H11.0002ZM10.0002 6C10.0002 5.44772 10.448 5 11.0002 5H14.0002C14.5525 5 15.0002 5.44772 15.0002 6C15.0002 6.55229 14.5525 7 14.0002 7H11.0002C10.448 7 10.0002 6.55229 10.0002 6ZM11.0002 15C10.448 15 10.0002 15.4477 10.0002 16C10.0002 16.5523 10.448 17 11.0002 17H14.0002C14.5525 17 15.0002 16.5523 15.0002 16C15.0002 15.4477 14.5525 15 14.0002 15H11.0002Z"
                                fill="currentColor"
                              />
                            </svg>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
                {/* <div>
                  <div className="fs-4 cursor-pointer" onClick={resetFilterBtn}>Reset All</div>
                </div> */}
              </div>

              <div className="results-main d-flex gap-32">
                <div className="results d-flex align-center flex-wrap gap-10">
                  <div className="d-flex flex-wrap gap-10">
                    {Object.entries(selectedCategories).map(
                      ([categoryName, selectedValue]: any) => (
                        <div key={categoryName}>
                          {selectedValue === "" ? (
                            ""
                          ) : (
                            <div
                              className="d-flex flex-wrap gap-10"
                              key={categoryName}
                            >
                              {
                                ((data?.multiselect_switch[categoryName]
                                  .multiselect === true ||
                                  data?.multiselect_switch[categoryName].checkbox ===
                                  true) && Array.isArray(selectedValue))
                                  ? selectedValue?.map((e: any, index: any) => {
                                    const label =
                                      e?.label?.replaceAll("-", " ") || e;
                                    return (
                                      <div className="result-checkbox" key={index}>
                                        {label}
                                        <img
                                          className="thumbnail"
                                          onClick={() =>
                                            handleRemoveCategory(categoryName, e)
                                          }
                                          src={closeimg}
                                          alt=""
                                        />
                                      </div>
                                    );
                                  })
                                  : (() => {
                                    const label = selectedValue.replaceAll(
                                      "-",
                                      " "
                                    );
                                    return (
                                      <div className="result-checkbox">
                                        {label}
                                        <img
                                          className="thumbnail"
                                          onClick={() =>
                                            handleRemoveCategory(
                                              categoryName,
                                              selectedValue
                                            )
                                          }
                                          src={closeimg}
                                          alt=""
                                        />
                                      </div>
                                    );
                                  })()}
                            </div>
                          )}
                        </div>
                      )
                    )}
                    {Object.entries(singleSelectedCategories).map(
                      ([categoryName, selectedValue]: any) => {
                        const label = selectedValue?.replaceAll("-", " ");
                        return (
                          <div key={categoryName}>
                            {selectedValue && (
                              <div
                                className="d-flex flex-wrap gap-10"
                                key={categoryName}
                              >
                                <div className="result-checkbox">
                                  {label}
                                  <img
                                    className="thumbnail"
                                    onClick={() =>
                                      handleSingleSelectRemove(categoryName)
                                    }
                                    src={closeimg}
                                    alt=""
                                  />
                                </div>
                              </div>
                            )}
                          </div>
                        );
                      }
                    )}
                  </div>
                </div>
              </div>
              <div
                className={
                  data?.layout === "List View" || isOn === "List View"
                    ? "filter-main d-block Listing"
                    : "filter-main d-flex"
                }
              >
                {displayedItems.length === 0 ? (
                  <>
                    {isLoading ? (
                      <Loader />
                    ) : (
                      <div className="NotFound-Box">
                        <h4 className="my-2 text-center No-data-found">
                          No results found.
                        </h4>
                        <p className="mb-0 mt-4">Please try different keywords.</p>
                      </div>
                    )}
                  </>
                ) : (
                  displayedItems.map((element: any, index: any) => (
                    <EmbeddedItem
                      {...element}
                      {...data}
                      layout={data?.layout}
                      site_url={data?.site_url}
                      InOn={isOn}
                      all_collection_data={data?.all_collection_data}
                      collectionMapping={data?.collection_mapping}
                      key={index}
                    />
                  ))
                )}
              </div>
              {isLoader ? (
                <div className="load-more-wrapper d-flex justify-content-center">
                  <div className="button-primary">Load More...</div>
                </div>
              ) : (
                filteredList.length > visibleItemCount && (
                  <div className="load-more-wrapper d-flex justify-content-center">
                    <div className="button-primary" onClick={handleLoadMore}>
                      Load More
                    </div>
                  </div>
                )
              )}
            </div>
          </div>--
        </div>
      </div>
    </>
  );
};

const EmbeddedWrapper = () => {
  const intl = useIntl();

  return (
    <>
      <PageTitle breadcrumbs={[]}>
        {intl.formatMessage({ id: "MENU.DASHBOARD" })}
      </PageTitle>
      <EmbeddedPage />
    </>
  );
};

export { EmbeddedWrapper };
