import { useIntl } from "react-intl";
import { PageTitle } from "../../../_metronic/layout/core";
import { TilesWidget2 } from "../../../_metronic/partials/widgets";
import { useAuth } from "../../modules/auth";

const DashboardPage = () => (
  <>
    <div className="row g-5 g-xl-8">
      <div className="col-xl-8">
        <div className="row gx-5 gx-xl-8 mb-5 mb-xl-8">
          <div className="col-xl-6">
            <TilesWidget2 className="card-xl-stretch mb-5 mb-xl-8" />
            <div className="row gx-5 gx-xl-8"></div>
          </div>
        </div>
      </div>
    </div>
  </>
);

const DashboardWrapper = () => {
  const intl = useIntl();

  const { currentUser } = useAuth();
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: "MENU.DASHBOARD" })}</PageTitle>
      <DashboardPage />
    </>
  );
};

export { DashboardWrapper };
